import { useTranslation } from 'react-i18next'

import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { CardDashboard, DocumentsGallery } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'

export function DonationMatchRequestReceipt({ id }: { id: string }) {
  const { t } = useTranslation()
  const { donationMatchingRequestService } = useServices()
  const [{ data, isLoading, errorMessage }] = useQuery(donationMatchingRequestService.getReceipt, {
    id
  })

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <CardDashboard title={t('typography.donationReceipt')} data-testid="match-request-donation-receipt">
      <DocumentsGallery files={[{ url: data.url }]} withPreview previewUrl="/document-preview" />
    </CardDashboard>
  )
}
