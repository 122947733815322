export const mockValidationSubmissionLogsResponse = {
  data: [
    {
      id: 'staging_vslog_000000Cycy1KXxEeF61DDmtksXxOF',
      validationSubmissionId: 'staging_validationsubmission_000000Cycy0zxaoBNYWklVPqed3dG',
      type: 'agent_verification_status_change',
      data: {
        updatedCheck: {
          check: 'agent_verification',
          status: 'rejected'
        }
      },
      createdAt: '2024-11-26T09:08:52.214Z'
    },
    {
      id: 'staging_vslog_000000Cycy1KXxEeF61DDmtksXxNv',
      validationSubmissionId: 'staging_validationsubmission_000000Cycy0zxaoBNYWklVPqed3dG',
      type: 'validation_submission_failed',
      data: null,
      createdAt: '2024-11-26T09:08:51.734Z'
    },
    {
      id: 'staging_vslog_000000Cycy0zxaoBNYWklVPqed3dX',
      validationSubmissionId: 'staging_validationsubmission_000000Cycy0zxaoBNYWklVPqed3dG',
      type: 'agent_verification_status_change',
      data: {
        updatedCheck: {
          check: 'agent_verification',
          status: 'pending_review'
        }
      },
      createdAt: '2024-11-26T09:08:49.931Z'
    }
  ],
  object: 'validation_submission_logs'
}
