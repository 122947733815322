import { Axios, AxiosResponse } from 'axios'

import { Organisation, Partner } from '../admin/admin.types'

import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  GetDonationListProps,
  GetTotalDonationsWithAmountProps,
  Donation,
  DonationEnhanced,
  PostDonationsBatchCsvProps,
  PostDonationsBatchProps,
  PostDonationsBatchResponse,
  PostReassignDonationProps,
  TotalDonationsCountWithTotal,
  PostReassignAllDonationsProps,
  PostReassignAllDonationsResponse
} from './donations.types'

export const donationsService = ({ percentClient }: { percentClient: Axios }) => ({
  getDonations: async (params: GetDonationListProps): Promise<AxiosResponse<PBPListResponse<DonationEnhanced>>> => {
    const donations = await percentClient.get<PBPListResponse<Donation>>('v1/admin/donations', {
      params
    })

    if (donations.data.data.length === 0 || donations.status !== 200) {
      return donations as AxiosResponse<PBPListResponse<DonationEnhanced>>
    }
    const partnerIds = Array.from(new Set(donations.data.data.map(({ partnerId }) => partnerId)))
    const organisationIds = Array.from(new Set(donations.data.data.map(({ organisationId }) => organisationId)))
    const [partners, organisations] = await Promise.all([
      percentClient.get<PBPListResponse<Partner>>('v1/admin/partners', { params: { partnerIds } }),
      percentClient.get<PBPListResponse<Organisation>>('v1/admin/organisations', { params: { organisationIds } })
    ])

    if (partners.status !== 200) {
      return partners as unknown as AxiosResponse<PBPListResponse<DonationEnhanced>>
    }

    if (organisations.status !== 200) {
      return organisations as unknown as AxiosResponse<PBPListResponse<DonationEnhanced>>
    }
    const partnerMap = new Map(partners.data.data.map(props => [props.id, props]))
    const organisationsMap = new Map(organisations.data.data.map(props => [props.id, props]))

    return {
      ...donations,
      data: {
        ...donations.data,
        data: donations.data.data.map(props => ({
          ...props,
          partner: partnerMap.get(props.partnerId)!,
          organisation: organisationsMap.get(props.organisationId)!
        }))
      }
    }
  },
  reassignDonation: async ({ donationId, payload }: PostReassignDonationProps) =>
    percentClient.post<PBPGetResponse<Donation>>(`v1/admin/donations/${donationId}/reassign`, payload),
  reassignAllDonations: async ({ payload }: PostReassignAllDonationsProps) =>
    percentClient.post<PBPGetResponse<PostReassignAllDonationsResponse>>('v1/admin/donations/reassign-all', payload),
  uploadDonationsBatchCsv: ({ donationsBatchCSV }: PostDonationsBatchCsvProps) =>
    percentClient.post<any>('v1/admin/donations-batch', donationsBatchCSV, {
      headers: {
        'Content-Type': 'text/csv'
      }
    }),
  uploadDonationsBatch: ({ payload }: PostDonationsBatchProps) =>
    percentClient.post<PostDonationsBatchResponse>('v1/admin/donations-batch', payload),
  getTotalDonationsWithAmount: async ({
    organisationId,
    currencyCode
  }: GetTotalDonationsWithAmountProps): Promise<AxiosResponse<PBPGetResponse<TotalDonationsCountWithTotal>>> =>
    percentClient.get<PBPGetResponse<TotalDonationsCountWithTotal>>(
      `v1/admin/donations/organisations/${organisationId}/total`,
      {
        params: {
          currency: currencyCode
        }
      }
    )
})
