import { TableCell, TableRow } from '@material-ui/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { Table } from '@percent/admin-dashboard/common/components'
import { Badge, Chip, FilterList, SearchFilterList, SearchInput } from '@percent/lemonade'
import { ValidationSubmissionsTableProps } from './ValidationSubmissionsTable.types'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import { statusMap } from '@percent/admin-dashboard/constants/statusMap'
import { getDate } from '@percent/admin-dashboard/common/utility'
import styles from './ValidationSubmissionsTable.module.scss'
import {
  useStatusesFilter,
  useValidationSubmissionSearch,
  usePartnerNameFilter,
  useCountryFilter,
  useProgramNameFilter
} from './hooks'

export function ValidationSubmissionsTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams
}: Readonly<ValidationSubmissionsTableProps>) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const { statusesOptions, onStatusChange, selectedStatuses } = useStatusesFilter({ queryParams, setQueryParams })
  const { searchValue, handleClearValue, handleKeyChange, handleKeyPress } = useValidationSubmissionSearch({
    queryParams,
    setQueryParams
  })
  const { selectedPartners, onPartnerChange, partnerNameOptions, isPartnerNameFilterLoading, partnerSearch } =
    usePartnerNameFilter({
      queryParams,
      setQueryParams
    })
  const { countryOptions, selectedCountries, onCountryChange, countrySearch } = useCountryFilter({
    queryParams,
    setQueryParams
  })
  const { programNameOptions, onProgramChange, selectedPrograms, isSelectedProgramsFilterDisabled } =
    useProgramNameFilter({
      queryParams,
      setQueryParams
    })

  const cells = [
    { id: 'country', isSortable: false },
    { id: 'organization', isSortable: false },
    { id: 'agentEmail', isSortable: false },
    { id: 'status', isSortable: false },
    { id: 'applicationDate', isSortable: false }
  ]

  const partnerNameChipValue = useMemo(
    () => partnerNameOptions?.find(option => option.value === queryParams.partnerId)?.label,
    [queryParams.partnerId, partnerNameOptions]
  )

  const countryChipValue = useMemo(
    () =>
      countryOptions
        .filter(option => queryParams.countryCodes?.includes(option.value))
        .map(option => option.label)
        .join(', '),
    [queryParams.countryCodes, countryOptions]
  )

  const handleRowClick = (id: string) => {
    return push(`/validation-submissions/${id}`)
  }

  const filters = useMemo(
    () => (
      <div className={styles.filtersAndChipsContainer}>
        <div className={styles.filtersContainer}>
          <SearchInput
            value={searchValue ?? ''}
            handleClearValue={handleClearValue}
            onKeyDown={handleKeyPress}
            onChange={handleKeyChange}
            placeholder={t('table.searchByAgentsEmailOrSpecificCheck')}
          />
          <div className={styles.filtersWrapper}>
            <SearchFilterList
              options={countryOptions}
              selectedValues={selectedCountries}
              onChange={onCountryChange}
              searchValue={countrySearch.countrySearchValue}
              onSearchChange={countrySearch.onCountrySearchChange}
              handleClearValue={countrySearch.onClearCountrySearchValue}
              onKeyDown={countrySearch.handleKeyPress}
              onButtonClick={countrySearch.onCountrySearchButtonClick}
              testId="country-search-filter"
              buttonText={t('typography.search')}
              placeholder={t('typography.searchByCountry')}
              label={t('typography.country')}
              placement="bottom"
            />
            <SearchFilterList
              options={partnerNameOptions}
              isLoading={isPartnerNameFilterLoading}
              selectedValues={selectedPartners}
              onChange={onPartnerChange}
              searchValue={partnerSearch.partnerNameSearch}
              onSearchChange={partnerSearch.onPartnerSearchChange}
              handleClearValue={partnerSearch.onClearPartnerSearchValue}
              onKeyDown={partnerSearch.handleKeyPress}
              onButtonClick={partnerSearch.onPartnersSearchButtonClick}
              testId="partner-name-search-filter"
              buttonText={t('typography.search')}
              placeholder={t('typography.search')}
              label={t('typography.partner')}
              placement="bottom"
            />
            <FilterList
              label={t('typography.programName')}
              options={programNameOptions}
              selectedValues={selectedPrograms}
              onChange={onProgramChange}
              isDisabled={isSelectedProgramsFilterDisabled}
              tooltipContent={t('typography.programNameTooltip')}
              testId="program-name-filter"
            />
            <FilterList
              label={t('typography.overallResult')}
              options={statusesOptions}
              selectedValues={selectedStatuses}
              onChange={onStatusChange}
              testId="overall-result-filter"
            />
          </div>
        </div>
        <div className={styles.filtersChipContainer}>
          {queryParams.partnerId && (
            <Chip
              label={t('typography.partner')}
              value={partnerNameChipValue}
              onClick={() => onPartnerChange([])}
              testId="partner-filter-chip"
            />
          )}
          {!!queryParams.countryCodes?.length && (
            <Chip
              label={t('typography.country')}
              value={countryChipValue}
              onClick={() => {
                onCountryChange([])
                setQueryParams({ ...queryParams, countryCodes: [] })
              }}
              testId="country-filter-chip"
            />
          )}
        </div>
      </div>
    ),
    [
      searchValue,
      handleClearValue,
      handleKeyPress,
      handleKeyChange,
      t,
      countryOptions,
      selectedCountries,
      onCountryChange,
      countrySearch,
      partnerNameOptions,
      isPartnerNameFilterLoading,
      selectedPartners,
      onPartnerChange,
      partnerSearch,
      statusesOptions,
      selectedStatuses,
      onStatusChange,
      programNameOptions,
      selectedPrograms,
      onProgramChange,
      isSelectedProgramsFilterDisabled
    ]
  )

  return (
    <Table
      isLoading={isLoading}
      totalResults={errorMessage ? 0 : totalResults || 0}
      data={data}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={cells}
      filtersContent={filters}
      emptyTableText={t('typography.noSearchResults')}
    >
      {data?.map(row => (
        <TableRow className={styles.row} key={row.id} onClick={() => handleRowClick(row.id)}>
          <CountryCodeFlag countryCode={row.organisation.countryCode ?? row.validationRequest.countryCode} />
          <TableCell>{row.organisation.name ?? row.validationRequest.name}</TableCell>
          <TableCell className={styles.email}>{row.agentVerification.email || t('status.na')}</TableCell>
          <TableCell>
            <Badge variant={statusMap[row.status].variant} icon={statusMap[row.status].icon}>
              {t(statusMap[row.status].text)}
            </Badge>
          </TableCell>
          <TableCell>{getDate(row.createdAt)}</TableCell>
          <TableCell>
            <DropdownSVG className={styles.arrow} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
