import { ChangeEvent, EventHandler, useCallback, useState, KeyboardEvent } from 'react'

import { ValidationSubmissionsTableProps } from '../ValidationSubmissionsTable.types'

export const useValidationSubmissionSearch = ({
  queryParams,
  setQueryParams
}: Pick<ValidationSubmissionsTableProps, 'queryParams' | 'setQueryParams'>) => {
  const [searchValue, setSearchValue] = useState<string>(queryParams.query || '')

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value.trim()
        })
      }
    },
    [setQueryParams]
  )

  const handleClearValue = useCallback(() => {
    setSearchValue('')
    setQueryParams({ query: undefined })
  }, [setQueryParams])

  return {
    searchValue,
    handleKeyChange,
    handleKeyPress,
    handleClearValue
  }
}
