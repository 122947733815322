import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UpdateOrganisationModal } from '../UpdateOrganisationModal/UpdateOrganisationModal'

import { Alert, Button, ButtonText, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { DonationMatchRequestDetailsOrganisationDetailsViewProps } from './DonationMatchRequestDetailsOrganisationDetailsView.types'
import styles from './DonationMatchRequestDetailsOrganisationDetailsView.module.scss'
import { config } from '@percent/admin-dashboard/config'

export function DonationMatchRequestDetailsOrganisationDetailsView({
  status,
  data,
  handleVerify,
  isLoading,
  donationMatchRequestId,
  refresh,
  handleModalOpenState
}: DonationMatchRequestDetailsOrganisationDetailsViewProps) {
  const { t } = useTranslation()
  const [isUpdateOrganisationModalOpen, setIsUpdateOrganisationModalOpen] = useState(false)

  return (
    <>
      <CardDashboard
        title={t('typography.organisationDetails')}
        action={
          (status === 'processing' || status === 'validating') && (
            <Button
              variant="secondary"
              onPress={
                status === 'processing'
                  ? data?.type === 'match_request_organisation_details'
                    ? handleModalOpenState
                    : handleVerify
                  : () => setIsUpdateOrganisationModalOpen(true)
              }
              loading={status === 'processing' ? isLoading : undefined}
              data-testid={status === 'processing' ? 'verify-organisation' : undefined}
            >
              {status === 'processing'
                ? t('button.donationMatchRequest.verifyOrganisation')
                : t('button.donationMatchRequest.updateOrganisation')}
            </Button>
          )
        }
      >
        {(status === 'processing' || status === 'validating') && (
          <Alert
            variant="warning"
            title={
              status === 'validating' ? t('typography.validatingVerification') : t('typography.pendingVerification')
            }
          >
            {t('typography.reviewVerificationStatusStart')}{' '}
            <a
              href={`${config.donationMatchRequest.dashboardLink}?donation_match_request_id=${donationMatchRequestId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Metabase
            </a>{' '}
            {t('typography.reviewVerificationStatusEnd')}
          </Alert>
        )}
        <Spacer size={4} axis="vertical" />
        <CardContent title={t('typography.name')}>
          <div className={styles.cardContentName}>
            {data?.data?.name || t('typography.notAvailable')}
            {(status === 'processing' || status === 'validating') && (
              <ButtonText onPress={() => setIsUpdateOrganisationModalOpen(true)}>
                {status === 'processing' && t('button.donationMatchRequest.updateOrganisation')}
              </ButtonText>
            )}
          </div>
        </CardContent>
        <CardContent title={t('typography.registrationId')}>
          {data?.data?.registryId || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.country')}>
          {data?.data?.countryCode || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.website')}>
          {(data?.type === 'organisation' && data?.data.website) ||
            (data?.type === 'match_request_organisation_details' && data?.data?.websiteUrl) ||
            t('typography.notAvailable')}
        </CardContent>
      </CardDashboard>
      <UpdateOrganisationModal
        open={isUpdateOrganisationModalOpen}
        onClose={() => setIsUpdateOrganisationModalOpen(false)}
        refresh={() => refresh?.()}
        donationMatchRequestId={donationMatchRequestId}
      />
    </>
  )
}
