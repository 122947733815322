import { factory, primaryKey } from '@mswjs/data'

const dbBase = {
  countryCode: String,
  organisationId: primaryKey(String),
  organisationName: String,
  claimer: { email: String },
  status: String,
  requestDate: String,
  document: {
    url: String
  },
  reference: String
}

const fieldsBase = {
  errorMessage: String,
  hint: String,
  label: String,
  regex: String,
  required: Boolean
}

export const dbDomestic = factory({
  bankDetails: {
    ...dbBase,
    bankAccountDetails: {
      holderName: String,
      wireTransferDetails: {
        type: String,
        bankCode: String,
        accountNumber: String
      }
    }
  }
})

export const dbIBAN = factory({
  bankDetails: {
    ...dbBase,
    bankAccountDetails: {
      holderName: String,
      wireTransferDetails: {
        type: String,
        bic: String,
        iban: String
      }
    }
  }
})

export const dbDomesticWithBIC = factory({
  bankDetails: {
    ...dbBase,
    requiredBankAccountDetails: {
      fields: {
        accountNumber: fieldsBase,
        bankCode: fieldsBase,
        bic: fieldsBase,
        branchCode: fieldsBase
      },
      type: String
    }
  }
})
