import { Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DonationMatchRequestStatus } from '@percent/admin-dashboard/api/actions/donationMatchRequests/donationMatchRequests.types'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { CardDashboard, Button, Text } from '@percent/lemonade'
import styles from './DonationMatchRequestDetailsHeader.module.scss'
import { DonationMatchRequestStatusBadge } from '@percent/admin-dashboard/app/donationMatchRequest/donationMatchRequestStatusBadge/DonationMatchRequestStatusBadge'

type DonationMatchRequestDetailsProps = {
  id: string
  partnerId: string
  createdAt: string
  status: DonationMatchRequestStatus
  showApproveModal: VoidFunction
  showRejectModal: VoidFunction
}

export function DonationMatchRequestDetailsHeader({
  id,
  partnerId,
  createdAt,
  status,
  showApproveModal,
  showRejectModal
}: DonationMatchRequestDetailsProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()
  const [{ data, isLoading, errorMessage }] = useQuery(adminService.queries.getPartnerDetail, { id: partnerId })

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <CardDashboard title={id}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <ul className={styles.headerList}>
          <li>
            <strong>{t('typography.partner')}</strong>&nbsp;
            <Text size="small">{data?.name}</Text>
          </li>
          <Divider orientation="vertical" flexItem />
          <li>
            <DonationMatchRequestStatusBadge status={status} />
          </li>
          <Divider orientation="vertical" flexItem />
          <li>
            <strong>{t('typography.submitted')}</strong>&nbsp;
            {dayJS(createdAt).format('D MMM YYYY, HH:mm')}
          </li>
        </ul>
        {(status === 'processing' || status === 'validating') && (
          <div
            style={{
              display: 'flex'
            }}
          >
            <div style={{ margin: 5 }}>
              <Button variant="reject" onPress={showRejectModal}>
                {t('button.reject')}
              </Button>
            </div>
            <div style={{ margin: 5 }}>
              <Button variant="approve" onPress={showApproveModal}>
                {t('button.approve')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </CardDashboard>
  )
}
