import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  RelationshipDropdownValues,
  RelationshipTypeValues,
  RelationshipValues
} from '../relatedOrganisations/RelatedOrganisations.types'
import { RelationshipBadge } from '../relationshipBadge/RelationshipBadge'

import { Dialog, Loader } from '@percent/admin-dashboard/common/components'
import { useMutation, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ApproveModalProps } from './Dialog.types'
import styles from './Dialog.module.scss'
import { Badge, Button, FormField, Spacer } from '@percent/lemonade'
import { ActionsDialogAction } from '@percent/admin-dashboard/common/components/dialog/actionDialog/ActionDialog'
import { renderAddressString } from '@percent/admin-dashboard/common/utility/renderAddressString'
import { filterMutuallyExclusiveOrganisationTypes } from '@percent/admin-dashboard/app/validations/nonprofitDetail/utils/filterMutuallyExclusiveOrganisationTypes'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { SimilarOrganisationActionValues } from '@percent/admin-dashboard/app/validations/nonprofitDetail/similarOrganisations/SimilarOrganisations.types'
import { DecoratedActivitySubTag } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { CountrySpecificData } from './registryModal/RegistryModal.types'

export const createRelationshipType = (relationshipType?: '' | RelationshipDropdownValues) => {
  switch (relationshipType) {
    case RelationshipDropdownValues.MERGE:
      return RelationshipTypeValues.MERGE
    case RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD:
    case RelationshipDropdownValues.FISCAL_SPONSORSHIP_PARENT:
      return RelationshipTypeValues.FISCAL_SPONSORSHIP
    default:
      return RelationshipTypeValues.BRANCH
  }
}

export function ApproveModal({
  isOpened,
  setIsOpened,
  finishAction,
  validationRequestId,
  validationRegistryId,
  nonprofitName,
  validationCountryCode,
  registryId,
  organisationTypes,
  validationOrganisationTypes,
  relationshipType,
  relationshipOrganisation,
  registryInformationNotFound,
  duplicateAction,
  duplicateOrganisation,
  subTags,
  selectedSubTags,
  insufficientSubTagsInformation,
  noValidSubtags,
  countrySpecificData
}: ApproveModalProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()
  const { countrySpecificDataFeatureFlag, registriesSupportedTypesFeatureFlag } = useFeatureFlag()

  const [{ errorMessage, isLoading, success }, { apiFunc }] = useMutation(
    adminService.commands.acceptValidation,
    finishAction
  )
  const isIndianValidationRequest = validationCountryCode === 'IND'

  const queryParams = {
    countryCode: validationCountryCode,
    pageSize: undefined,
    supportedTypes: registriesSupportedTypesFeatureFlag ? organisationTypes : undefined
  }

  const [{ dataOrNull }, { query }] = useQueryList(adminService.getRegistries, queryParams)

  useEffect(() => {
    if (registriesSupportedTypesFeatureFlag) {
      query(queryParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registriesSupportedTypesFeatureFlag])

  const isMergeRelationship = relationshipType === RelationshipDropdownValues.MERGE

  const isChildRelation =
    relationshipType &&
    [RelationshipDropdownValues.BRANCH_CHILD, RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD].includes(
      relationshipType
    )

  const relationshipValue = isChildRelation ? RelationshipValues.CHILD : RelationshipValues.PARENT

  const concatSubmitedOrganisationTypes = () =>
    filterMutuallyExclusiveOrganisationTypes(organisationTypes, [
      ...(duplicateOrganisation?.organisationTypes ?? []),
      ...(relationshipOrganisation?.result?.organisationTypes ?? [])
    ])

  const submittedOrganisationTypes = concatSubmitedOrganisationTypes()

  const relationshipData = {
    relationships: [
      {
        organisationId: relationshipOrganisation?.result.id,
        type: createRelationshipType(relationshipType),
        ...(!isMergeRelationship ? { relationship: relationshipValue } : {})
      }
    ]
  }

  const registry = dataOrNull?.find(({ id }) => registryId === id)

  const duplicateDisplayRegistryName =
    duplicateAction === SimilarOrganisationActionValues.MERGE
      ? dataOrNull?.find(({ id }) => duplicateOrganisation?.registry === id)?.englishName ||
        dataOrNull?.find(({ id }) => duplicateOrganisation?.registry === id)?.name
      : t('dialog.similarOrganisations.systemWillGenerateRegistry')

  const displayRegistry = registryInformationNotFound
    ? duplicateDisplayRegistryName
    : registry?.englishName || registry?.name

  const duplicateOrganisationRegistryIdText =
    duplicateAction === SimilarOrganisationActionValues.MERGE
      ? duplicateOrganisation?.registryId
      : t('dialog.similarOrganisations.systemWillGenerateRegistryId')

  const organisationRegistryId = registryInformationNotFound
    ? duplicateOrganisationRegistryIdText
    : validationRegistryId

  const duplicateOrganisationRegistryId =
    duplicateAction === SimilarOrganisationActionValues.MERGE ? duplicateOrganisation?.registryId : undefined

  const duplicateRegistryId =
    duplicateAction === SimilarOrganisationActionValues.MERGE ? duplicateOrganisation?.registry : undefined

  const submittedSubTags =
    subTags?.map(subTag => ({
      id: subTag.id,
      meetsCriteria: Boolean(selectedSubTags?.includes(subTag.id))
    })) || []

  const formatCountrySpecificData = (data?: CountrySpecificData) => {
    if (!data) return {}

    if (!countrySpecificDataFeatureFlag || !isIndianValidationRequest) return {}

    const truthyCountrySpecificData = Object.entries(data || {}).reduce(
      (acc, [key, value]) => {
        if (value) {
          return { ...acc, countrySpecificData: { ...acc.countrySpecificData, [key]: value } }
        }

        return acc
      },
      { countrySpecificData: {} }
    )

    if (Object.keys(truthyCountrySpecificData.countrySpecificData).length) {
      return truthyCountrySpecificData
    }

    return {}
  }
  const formatSubTagsSubmission = () => {
    if (noValidSubtags) {
      return {
        eligibilitySubscription: {
          subTags: subTags?.map(subTag => ({
            id: subTag.id,
            meetsCriteria: false
          }))
        }
      }
    }

    if (insufficientSubTagsInformation) {
      return { eligibilitySubscription: { subTags: null } }
    }

    return submittedSubTags.length ? { eligibilitySubscription: { subTags: submittedSubTags } } : {}
  }

  const handleSubmit = () => {
    apiFunc({
      payload: {
        registryId: registryInformationNotFound ? duplicateRegistryId : registryId,
        organisationRegistryId: registryInformationNotFound ? duplicateOrganisationRegistryId : validationRegistryId,
        featureFlagDelphi97: true,
        organisationTypes: submittedOrganisationTypes,
        ...formatSubTagsSubmission(),
        ...(relationshipType ? relationshipData : {}),
        ...(duplicateAction === SimilarOrganisationActionValues.MERGE
          ? {
              relationships: [
                {
                  organisationId: duplicateOrganisation?.id,
                  type: SimilarOrganisationActionValues.MERGE
                }
              ]
            }
          : {}),
        ...formatCountrySpecificData(countrySpecificData)
      },
      validationRequestId
    })
  }

  const disableConfirmButton = !registryInformationNotFound && (!registryId || !validationRegistryId)

  const partnerProgramMessageId = `${t('dialog.eligibleForPartnerProgram')} ${(
    validationOrganisationTypes || submittedOrganisationTypes
  )
    ?.map(organisationType => t(`organisationTypes.${organisationType}`))
    .join(' & ')
    .toLowerCase()} ${t('dialog.eligibleForPartnerProgramEnding')}`

  const successMessage = partnerProgramMessageId

  const canShowRelatedOrganisation =
    isMergeRelationship ||
    (relationshipType &&
      [RelationshipDropdownValues.BRANCH_CHILD, RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD].includes(
        relationshipType
      ))

  const isOnlyEducationOrganisation =
    organisationTypes?.length === 1 && organisationTypes.includes(OrganisationTypes.Education)

  const relationTypeText = t(`dialog.relatedOrganisations.${isMergeRelationship ? 'merge' : 'parent'}`)

  return (
    <Dialog
      openModal={isOpened}
      onClose={() => setIsOpened(false)}
      withoutHeader={success || !!errorMessage}
      headerTitle={t('dialog.confirm.title')}
    >
      {isLoading ? (
        <Loader />
      ) : errorMessage ? (
        <ActionsDialogAction
          handleOnClose={() => setIsOpened(false)}
          description={errorMessage}
          title={t('dialog.somethingWentWrong')}
          buttonTitle={t('button.done')}
          successIcon={false}
        />
      ) : success ? (
        <ActionsDialogAction
          handleOnClose={() => setIsOpened(false)}
          description={`${nonprofitName} ${successMessage}`}
          title={t('dialog.hasBeenApproved')}
          buttonTitle={t('button.done')}
          successIcon
        />
      ) : (
        <>
          <Grid item xs={12} className={styles.modalBody}>
            <Typography>
              {t('dialog.confirm.description')} <span style={{ fontFamily: 'MarkProMedium' }}>{nonprofitName}: </span>
            </Typography>
          </Grid>

          <Grid item xs={12} className={styles.form}>
            <FormField label={t('dialog.approveValdiationRequest.organisationType')} data-testid="organisationType">
              <div>
                {submittedOrganisationTypes?.map((organisationType: OrganisationTypes) => (
                  <>
                    <Badge variant="informative" icon="categories">
                      {t(`organisationTypes.${organisationType}`)}
                    </Badge>
                    <Spacer size={4} />
                  </>
                ))}
              </div>
            </FormField>
          </Grid>
          {isOnlyEducationOrganisation && (
            <Grid item xs={12} className={styles.form}>
              <FormField label={t('table.eligibityTags')} data-testid="eligibilityTags">
                <div>
                  {insufficientSubTagsInformation && (
                    <Typography variant="body2">
                      {t('dialog.submitTags.flagOrganisation')}
                      <span style={{ fontFamily: 'MarkProMedium' }}> {nonprofitName}.</span>
                    </Typography>
                  )}
                  {noValidSubtags && <Typography variant="body2">{t('typography.noValidSubtags')}</Typography>}
                  {!!selectedSubTags?.length &&
                    selectedSubTags?.map(selectedSubTagId => (
                      <>
                        <Badge variant="default" icon="categories">
                          {
                            (subTags as Array<DecoratedActivitySubTag>)?.find(
                              ({ id: subTagId }) => selectedSubTagId === subTagId
                            )?.name
                          }
                        </Badge>
                        <Spacer size={4} />
                      </>
                    ))}
                </div>
              </FormField>
            </Grid>
          )}
          <Grid item xs={12} className={styles.form}>
            <FormField label={t('table.registry')} data-testid="registryName">
              <Typography variant="body2">{displayRegistry}</Typography>
            </FormField>
          </Grid>
          <Grid item xs={12} className={styles.form}>
            <FormField label={t('typography.registryId')} data-testid="registryId">
              <Typography variant="body2">{organisationRegistryId}</Typography>
            </FormField>
          </Grid>
          {countrySpecificDataFeatureFlag && isIndianValidationRequest && (
            <Grid item xs={12} className={styles.form}>
              <FormField label={t('dialog.approveValdiationRequest.fcraId')} data-testid="registryId">
                <Typography variant="body2">
                  {countrySpecificData?.fcra ? countrySpecificData?.fcra : t('typography.notProvided')}
                </Typography>
              </FormField>
            </Grid>
          )}

          {relationshipType && (
            <Grid item xs={12} className={styles.form}>
              <FormField label={t('dialog.relatedOrganisations.label')} data-testid="registryId">
                <>
                  <span className={styles.organisationRelationship}>
                    <Typography variant="body2">{t('dialog.relatedOrganisations.markedAs')}</Typography>{' '}
                    <RelationshipBadge relationshipType={relationshipType} />
                  </span>
                  {canShowRelatedOrganisation && (
                    <Typography variant="body2" className={styles.relationshipType}>
                      <span>{relationTypeText}</span>
                      {relationshipOrganisation ? (
                        <span className={styles.organisationName}>
                          {relationshipOrganisation?.result.name}
                          {relationshipOrganisation?.result.address && (
                            <div>{renderAddressString(relationshipOrganisation?.result.address)}</div>
                          )}
                        </span>
                      ) : (
                        <span className={styles.organisationName}>
                          {t('form.relatedOrganisations.parentNotInDatabase')}
                        </span>
                      )}
                    </Typography>
                  )}
                </>
              </FormField>
            </Grid>
          )}
          {registryInformationNotFound && duplicateAction === SimilarOrganisationActionValues.MERGE && (
            <Grid item xs={12} className={styles.form}>
              <FormField label={t('dialog.similarOrganisations.label')} data-testid="confirmSimilarOrganisation">
                <Typography variant="body2">
                  <span className={styles.organisationName}>
                    {duplicateOrganisation?.name}
                    {duplicateOrganisation?.address && <div>{renderAddressString(duplicateOrganisation?.address)}</div>}
                  </span>
                </Typography>
              </FormField>
            </Grid>
          )}
          <Grid item xs={12} className={styles.modalBody}>
            <Typography>{t('dialog.confirm.footer')}</Typography>
          </Grid>
          <Grid item xs={12} className={styles.dialogFooter}>
            <Button
              disabled={disableConfirmButton}
              type="submit"
              variant="primary"
              data-testid="btn-confirm-validation"
              loading={isLoading}
              onPress={handleSubmit}
            >
              {t('button.confirmInformation')}
            </Button>
            <Spacer size={4} />
            <Button
              variant="secondary"
              data-testid="btn-cancel-validiation"
              onPress={() => {
                setIsOpened(false)
              }}
            >
              {t('button.cancel')}
            </Button>
          </Grid>
        </>
      )}
    </Dialog>
  )
}
