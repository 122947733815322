import { DecodedValueMap, SetQuery } from 'use-query-params'

import { Services } from '@percent/admin-dashboard/api/actions'
import { MatchRequestsTableQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'
import { DonationMatchRequestEnhanced } from '@percent/admin-dashboard/api/actions/donationMatchRequests/donationMatchRequests.types'

export enum DonationMatchRequestsStatusFilter {
  PENDING,
  APPROVED,
  REJECTED,
  VALIDATING
}

export type DonationMatchRequestsTableProps = {
  isLoading: boolean
  totalResults: number
  data: DonationMatchRequestEnhanced[] | null
  previousPage: (() => void) | null
  nextPage: (() => void) | null
  refreshToFirstPage: (() => void) | null
  errorMessage: string | null
  query: (..._: Parameters<Services['donationMatchingRequestService']['getMatchRequests']>) => void
  queryParams: DecodedValueMap<MatchRequestsTableQueryParams>
  setQueryParams: SetQuery<MatchRequestsTableQueryParams>
}
