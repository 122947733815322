import { ValidationSubmissionConfiguration } from '@percent/admin-dashboard/api/actions/validationSubmissions/validationSubmissions.types'

export const mockValidationSubmissionConfigurationsResponse: { data: ValidationSubmissionConfiguration[] } = {
  data: [
    {
      id: 'staging_hostedconfiguration_000000CyxL4J0kqI6c2G1Gy1ih4uP',
      name: '',
      partnerId: 'staging_partner_000000CyxL4J0kqI6c2G1Gy1ih4uO'
    },
    {
      id: 'staging_hostedconfiguration_000000CyxL4J0kqI6c2G1Gy1ih4uP',
      name: 'Test configuration',
      partnerId: 'staging_partner_000000CyxL4J0kqI6c2G1Gy1ih4uO'
    }
  ]
}
