import { useState } from 'react'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import { useTranslation } from 'react-i18next'

import { Button } from '@percent/lemonade'
import { UploadDonationsImportProps } from './UploadDonationsImport.types'
import { useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { CurrencyInfo } from '@percent/admin-dashboard/api/actions/currencies/currencies.types'

const INT_32_MAX = 2147483647
const hasDecimals = (amount: string) => amount.indexOf('.') !== -1

export const toMinorUnits = (amountInMajor: number, currency: CurrencyInfo) =>
  Math.round(amountInMajor * 10 ** currency.minorUnits)
export const toMajorUnits = (amountInMinor: number, currency: CurrencyInfo) => amountInMinor / 10 ** currency.minorUnits

export function UploadDonationsCsv({ handleDonationBatchCreation, isUploadInProgress }: UploadDonationsImportProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { currencyInfo } = useCurrencies()

  const fields = [
    {
      label: 'Amount (Major)',
      key: 'majorAmount',
      alternateMatches: ['amount', 'amountMajor', 'majorAmount'],
      fieldType: {
        type: 'input'
      },
      example: '12.45',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Amount is required',
          level: 'error'
        },
        {
          rule: 'regex',
          value: '^[0-9 ,.]+$',
          errorMessage: 'Amount must be a number',
          level: 'error'
        }
      ]
    },
    {
      label: 'Currency',
      key: 'currencyCode',
      alternateMatches: ['currency', 'currencyCode'],
      fieldType: {
        type: 'select',
        options: currencyInfo.map(ci => ({ value: ci.code, label: ci.code }))
      },
      example: 'GBP',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Currency is required',
          level: 'error'
        }
      ]
    },
    {
      label: 'Organisation',
      key: 'organisationId',
      alternateMatches: ['organisation'],
      fieldType: {
        type: 'input'
      },
      example: 'organisation_xxxxxxxxxxxx',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Organisation is required',
          level: 'error'
        }
      ]
    },
    {
      label: 'Partner',
      key: 'partnerId',
      alternateMatches: ['partner'],
      fieldType: {
        type: 'input'
      },
      example: 'partner_xxxxxxxxxxx',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Partner is required',
          level: 'error'
        }
      ]
    },
    {
      label: 'Subsidiary',
      key: 'subsidiaryId',
      alternateMatches: ['subsidiary', 'regional_entity'],
      fieldType: { type: 'input' },
      example: 'subsidiary_xxxxxxxxxx'
    },
    {
      label: 'Created',
      key: 'createdAt',
      alternateMatches: ['donation created'],
      fieldType: {
        type: 'input'
      },
      example: '2023-06-01',
      validations: []
    }
  ] as const

  return (
    <>
      <Button size="small" onPress={() => setOpen(!open)} disabled={isUploadInProgress} loading={isUploadInProgress}>
        {t('button.importDonations')}
      </Button>
      <ReactSpreadsheetImport
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={data => {
          handleDonationBatchCreation(
            data.validData.map(row => ({
              majorAmount: Number(row.majorAmount!),
              currencyCode: row.currencyCode! as string,
              organisationId: row.organisationId! as string,
              subsidiaryId: row.subsidiaryId as string | undefined,
              partnerId: row.partnerId! as string,
              createdAt: row.createdAt as string | undefined
            }))
          )
        }}
        allowInvalidSubmit={false}
        maxRecords={500}
        rowHook={(data, addError) => {
          const amountAsNumber = Number(data.majorAmount)

          if (amountAsNumber <= 0) {
            addError('majorAmount', { message: 'Must be greater than 0', level: 'info' })
          }

          const currency = currencyInfo.find(ci => ci.code === data.currencyCode)

          if (typeof data.majorAmount === 'string' && hasDecimals(data.majorAmount) && currency) {
            const amountDecimalPlaces = data.majorAmount.split('.')[1].length

            if (amountDecimalPlaces > currency.minorUnits) {
              addError('majorAmount', {
                message: `too many decimal places (${amountDecimalPlaces}) for currency ${currency.code}`,
                level: 'error'
              })
            }
          }

          if (currency && toMinorUnits(amountAsNumber, currency) > INT_32_MAX) {
            addError('majorAmount', {
              message:
                `Number is too large, must be less than or equal to: ` +
                `${toMajorUnits(INT_32_MAX, currency)} for currency: ${currency.code}`,
              level: 'error'
            })
          }

          return data
        }}
        fields={fields}
      />
    </>
  )
}
