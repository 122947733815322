import { Timeline as MantineTimeline } from '@mantine/core'
import styled from 'styled-components'
import { TimelineProps } from './timeline.props'
import { theme } from '../../theme'

import '@mantine/core/styles/Timeline.css'

export const TimeLineItem = styled(MantineTimeline.Item)`
  ${({ theme }) => `
    font-size: 14px;
  
    .mantine-Timeline-itemContent {
      color: ${theme.colors.gray600};
    }

    span {
      display: flex;
      font-size: 12px;
    }
  `}
`

export const Timeline = ({ timeline }: Readonly<TimelineProps>) => (
  <MantineTimeline
    active={timeline.length}
    color={theme.colors.primary}
    bulletSize={12}
    lineWidth={1}
  >
    {timeline.map(({ title, description, additionalDescription }, index) => (
      <TimeLineItem title={title} bullet key={`${title}-${index}`}>
        {description}
        {additionalDescription && (
          <span data-testid={`timeline-additionalDescription-${index}`}>
            {additionalDescription}
          </span>
        )}
      </TimeLineItem>
    ))}
  </MantineTimeline>
)
