import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockDonationReceipt } from '@percent/admin-dashboard/fixtures/api/donations/mockDonationReceipt'

export const mockDonationMatchRequestUpdated = {
  accountId: 'staging_account_000000CjzlA6ISwxBlKDbWaI3eygq',
  amount: { amount: 80000, currency: 'GBP' },
  approvedAt: null,
  approvedBy: null,
  createdAt: '2023-12-18T12:21:02.068Z',
  createdBy: 'staging_account_000000CjzlA6ISwxBlKDbWaI3eygq',
  id: 'staging_donationmatchrequest_000000CkpWklX7YfmF0d71TgVJKSG',
  organisationDetails: null,
  organisationId: 'staging_organisation_000000CXL2hAoT6GxAJZIubRnLVX3',
  partnerId: 'staging_partner_000000CjaEDvxVBvj5UJ9CvcKovGC',
  rejectedAt: null,
  rejectedBy: null,
  rejectedReason: null,
  status: 'pending'
}

export const mockDonationMatchRequestApprove = {
  ...mockDonationMatchRequestUpdated,
  approvedAt: '2023-12-18T12:22:25.930Z',
  approvedBy: 'staging_account_000000CdP8NCOL5QfnBytEBGugcYy',
  status: 'approved'
}

export const mockDonationMatchRequestRejected = {
  ...mockDonationMatchRequestUpdated,
  rejectedAt: '2023-12-20T08:53:43.268Z',
  rejectedBy: 'staging_account_000000CdP8NCOL5QfnBytEBGugcYy',
  rejectedReason: 'Receipt is ineligible',
  status: 'rejected'
}

export const mockDonationAmountRequestUpdated = {
  ...mockDonationMatchRequestUpdated,
  amount: { ...mockDonationMatchRequestUpdated.amount, amount: 10000 }
}

export const donationMatchRequestHandlers = [
  rest.get(`${config.urls.api}v1/donation-match-requests/donation_match_request_id/receipt`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockDonationReceipt))
  })
]
