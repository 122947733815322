import { AppBar, Grid, MenuItem, Toolbar, Typography, Menu, Breadcrumbs } from '@material-ui/core'
import React from 'react'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useLocation, Redirect, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useBreadcrumbsData } from '../breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { SET_UNAUTHORISED } from '@percent/admin-dashboard/context/auth/authReducer/authReducer'
import { useAuthDispatch, useAuthState } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { ReactComponent as SettingsIcon } from '@percent/admin-dashboard/common/assets/images/settings.svg'
import { ReactComponent as LogoutIcon } from '@percent/admin-dashboard/common/assets/images/logout.svg'
import { replaceDashWithEmptySpace } from '@percent/admin-dashboard/common/utility/replaceDashWithEmptySpace'
import { Tooltip } from '@percent/admin-dashboard/common/components'
import styles from './TopBar.module.scss'
import { capitalizeString } from '@percent/admin-dashboard/common/utility/capitalizeString'

const replaceChars: Record<string, string> = {
  '/': '',
  ' ': '-'
}

const routerFormater = (path: string) => path && `/${path.replace(/(^\/)|(\s)/g, m => replaceChars[m])}`

export function TopBar() {
  const { path: locationPath } = useBreadcrumbsData()
  const { authService } = useServices()
  const dispatch = useAuthDispatch()
  const { user } = useAuthState()

  const { pathname } = useLocation()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    authService.logout().then(() => {
      localStorage.removeItem('authState')
      dispatch({
        type: SET_UNAUTHORISED
      })
    })

    return <Redirect to="/login" />
  }

  let paths = pathname.split('/')

  if (paths.length > 2 && paths[1] === 'partners' && locationPath) {
    paths = ['', 'partners', paths[2]]
  } else if (paths.length > 2 && paths[1] === 'validations' && locationPath) {
    paths = ['', 'validations', paths[2]]
  } else if (paths.length > 2 && paths[1] === 'bank-details-reviews' && locationPath) {
    paths = ['', replaceDashWithEmptySpace(paths[1]), locationPath]
  } else if (paths.length > 2 && paths[1] === 'activity' && locationPath) {
    paths = ['', `activity?status=${locationPath}`, paths[2]]
  } else if (paths.length > 2 && paths[1] === 'agent-verification' && locationPath) {
    paths = ['', 'agent-verification', paths[2]]
  } else if (paths.length > 2 && paths[1] === 'donation-match-requests') {
    paths = ['', 'donation-match-requests', paths[2]]
  } else if (paths.length > 2 && paths[1].includes('-') && locationPath) {
    paths = ['', replaceDashWithEmptySpace(paths[1]), paths[2]]
  } else if (paths[1].includes('-')) {
    paths = ['', replaceDashWithEmptySpace(paths[1])]
  } else if (paths.length > 2 && paths[1] === 'eligibility' && locationPath) {
    paths = ['', 'eligibility', locationPath]
  } else if (paths.length > 3) {
    paths = ['', 'organizations', paths[2], paths[3]]
  }

  return (
    <AppBar position="static" className={styles.topBar}>
      <Toolbar className={styles.toolBar}>
        <div className={styles.navContainer}>
          <div className={styles.ellipsisParent}>
            <div className={styles.ellipsisContainer}>
              <div className={styles.ellipsisText}>
                <Breadcrumbs
                  className={styles.breadcrumbs}
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  {paths.map(
                    (path: string, id: number) =>
                      path && (
                        <Tooltip
                          key={path}
                          interactive
                          arrow={false}
                          placement="top-start"
                          title={capitalizeString(path)}
                          disableHoverListener={id !== paths.length - 1}
                          disableTouchListener={id !== paths.length - 1}
                        >
                          {id !== paths.length - 1 ? (
                            <Link
                              className={styles.breadcrumb}
                              key={path}
                              to={paths
                                .slice(0, -1)
                                .reduce((acc: string, curVal: string) => routerFormater(acc) + routerFormater(curVal))}
                            >
                              {id === 1 ? path.replace(/\b\w/, l => l.toUpperCase()).split('?')[0] : path}
                            </Link>
                          ) : (
                            <div className={styles.breadcrumbActive}>
                              <span>{id === 1 ? capitalizeString(path) : path || locationPath}</span>
                            </div>
                          )}
                        </Tooltip>
                      )
                  )}
                </Breadcrumbs>
              </div>

              <div className={styles.ellipsisBox}>
                <Grid direction="row-reverse" container>
                  <MenuItem onClick={handleClick} className={styles.menuItem}>
                    <div className={styles.userDetail}>
                      <Typography className={styles.nameText} variant="body1">
                        {user?.fullName ?? user?.preferredName ?? user?.email}
                      </Typography>
                      <Typography className={styles.roleText} variant="body2">
                        Admin
                      </Typography>
                    </div>
                    <DropdownSVG className={anchorEl ? styles.dropdown : undefined} />
                  </MenuItem>
                  <div>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                      }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      className={styles.userProfileMenu}
                    >
                      <Link to="/my-account">
                        <MenuItem className={styles.userProfileMenuItem}>
                          <SettingsIcon />
                          {t('menu.accountSettings')}
                        </MenuItem>
                      </Link>
                      <MenuItem className={styles.userProfileMenuItem} onClick={handleLogout}>
                        <LogoutIcon />
                        {t('menu.logout')}
                      </MenuItem>
                    </Menu>
                  </div>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  )
}
