import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UpdateDonationAmountModal } from '../UpdateDonationAmountModal/UpdateDonationAmountModal'

import { CurrencyCode, formatMoney } from '@percent/utility'
import { DonationMatchRequestStatus } from '@percent/admin-dashboard/api/actions/donationMatchRequests/donationMatchRequests.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ButtonText, CardDashboard, CardContent, Loader } from '@percent/lemonade'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { ExternalLink } from '@percent/admin-dashboard/common/components/externalLink/ExternalLink'

export type DonationMatchRequestDetailsProps = {
  amount: {
    amount: number
    currency: CurrencyCode
  }
  status: DonationMatchRequestStatus
  externalMatchingUrl?: string
  id: string
  accountId: string
  refresh: VoidFunction
}

export function DonationMatchRequestDetails({
  amount,
  externalMatchingUrl,
  status,
  id,
  accountId,
  refresh
}: DonationMatchRequestDetailsProps) {
  const { t } = useTranslation()
  const { iamService } = useServices()
  const [{ data: getAccountDetails, isLoading, errorMessage }] = useQuery(iamService.getAccountDetail, {
    accountId
  })
  const [updateDonationAmountModalOpen, setUpdateDonationAmountModalOpen] = useState(false)
  const handleModal = () => {
    setUpdateDonationAmountModalOpen(prevState => !prevState)
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <CardDashboard title="Request Details">
        <CardContent title="Donation amount">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {formatMoney(amount)}
            {(status === 'processing' || status === 'validating') && (
              <ButtonText onPress={() => setUpdateDonationAmountModalOpen(true)}>
                {t('button.donationMatchRequest.editDonationAmount')}
              </ButtonText>
            )}
          </div>
        </CardContent>
        {externalMatchingUrl && (
          <CardContent title={t('typography.matchToCampaign')}>
            <ExternalLink url={externalMatchingUrl} />
          </CardContent>
        )}
        <CardContent title={t('typography.donorName')}>{getAccountDetails?.fullName}</CardContent>
        <CardContent title={t('typography.donorEmail')}>{getAccountDetails?.email}</CardContent>
      </CardDashboard>
      {updateDonationAmountModalOpen && (
        <UpdateDonationAmountModal
          open={updateDonationAmountModalOpen}
          onClose={handleModal}
          donationMatchRequestId={id}
          amount={amount}
          refresh={refresh}
        />
      )}
    </>
  )
}
