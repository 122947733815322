import React, { useEffect } from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistriesTable } from './RegistriesTable/RegistriesTable'

export function RegistriesContainer() {
  const { registriesService } = useServices()
  const [queryParams, setQueryParams] = useQueryParams({
    search: StringParam,
    page: withDefault(StringParam, '1')
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { nextPage, previousPage, query, refresh }] =
    useQueryList(registriesService.getRegistriesList, {
      ...queryParams
    })

  useEffect(() => {
    if (!queryParams.page) {
      setQueryParams(prev => ({ ...prev, page: '1' }), 'replace')
    }
  }, [queryParams.page, setQueryParams])

  useEffect(() => {
    if (!queryParams.search) {
      setQueryParams(prev => {
        const { search, ...rest } = prev

        return rest
      }, 'replace')
    }
  }, [queryParams.search, setQueryParams])

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <RegistriesTable
      data={dataOrNull}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      nextPage={nextPage}
      previousPage={previousPage}
      totalResults={totalResults}
      refresh={refresh}
    />
  )
}
