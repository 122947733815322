import { useState } from 'react'
import { useFormik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { FormField, Modal, Select, ActionModal, AcknowledgeModal, TextInput, Alert } from '@percent/lemonade'
import { AddToAutoRejectionListModalProps } from './AddToAutoRejectionListModal.types'
import { emailRegex } from '@percent/utility'
import { AgentVerificationRejectionReasonDropdownCodes } from '@percent/admin-dashboard/api/types'

const agentIsOnAllowListErrorCode = 'agent_is_already_on_allow_list'

const rejectionReasons = [{ label: 'Other', value: 'other' }]

export function AddToAutoRejectionListModal({
  isOpen,
  setIsOpen,
  refresh
}: Readonly<AddToAutoRejectionListModalProps>) {
  const { t } = useTranslation()
  const { autoRejectionListService } = useServices()
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const [{ isLoading, error }, { apiFunc: addToAutoRejectionList }] = useMutation(
    autoRejectionListService.addToAutoRejectonList,
    () => {
      setShowConfirmationScreen(true)
      refresh()
    },
    () => setShowErrorMessage(true)
  )

  const { values, setFieldValue, handleChange, errors, submitForm } = useFormik<{
    email: string
    rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes
  }>({
    initialValues: {
      email: '',
      rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes.Other
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string()
          .required('Required')
          .matches(emailRegex, t('errorMessage.validEmail'))
          .max(255, t('errorMessage.shouldNotExceed255'))
      }),
    onSubmit: () => {
      addToAutoRejectionList(values)
    }
  })

  const closeModal = () => {
    setIsOpen(false)
    setShowErrorMessage(false)
  }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {showConfirmationScreen && (
        <AcknowledgeModal
          handleClose={() => {
            closeModal()
            setShowConfirmationScreen(false)
          }}
          title={t('dialog.addToRejectionListConfirmation.title')}
          description={t('dialog.addToRejectionListConfirmation.description', { agentEmail: values.email })}
          result="positive"
          buttonText={t('button.done')}
        />
      )}

      {!showConfirmationScreen && (
        <ActionModal
          title={t('dialog.addToRejectionList.title')}
          primaryButtonText={t('button.addEmail')}
          primaryBtnTestId="add-to-rejection-list"
          secondaryButtonText={t('button.cancel')}
          handleClose={closeModal}
          handleSubmit={submitForm}
          loading={isLoading}
          disabled={!!errors.email || !values.email}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <FormField
              label={t('typography.agentsEmail')}
              statusMessage={errors.email}
              status={errors.email ? 'danger' : 'default'}
              data-testid="agent-email-input"
            >
              <TextInput name="email" onChange={handleChange} />
            </FormField>
            <Alert variant="info">
              {t('dialog.addToRejectionList.note.title')}
              <ul style={{ paddingLeft: 16 }}>
                <li>
                  <Trans i18nKey="dialog.addToRejectionList.note.email" components={{ b: <strong /> }} />
                </li>
                <li>
                  <Trans i18nKey="dialog.addToRejectionList.note.domain" components={{ b: <strong /> }} />
                </li>
              </ul>
            </Alert>
            <FormField label={t('dropdown.reject.title')} disabled>
              <>
                <Select
                  placeholder={t('dropdown.reject.placeholder')}
                  defaultValue={rejectionReasons[0]}
                  options={rejectionReasons}
                  onChange={({ value }) => setFieldValue('rejectionReasonCode', value)}
                  disabled
                  data-testid="rejection-reason-select"
                />
                <Alert variant="info">{t('dialog.addToRejectionList.alert')}</Alert>
              </>
            </FormField>
            {showErrorMessage && (
              <Alert variant="error">
                {error?.code?.includes(agentIsOnAllowListErrorCode)
                  ? t('dialog.addToRejectionList.agentIsOnAllowList')
                  : t('dialog.somethingWentWrong')}
              </Alert>
            )}
          </div>
        </ActionModal>
      )}
    </Modal>
  )
}
