import { ChangeEvent, EventHandler, useEffect, useState, KeyboardEvent } from 'react'
import { useDebounceValue } from 'usehooks-ts'

import { ValidationSubmissionsTableProps } from '../ValidationSubmissionsTable.types'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'

export const usePartnerNameFilter = ({
  queryParams,
  setQueryParams
}: Pick<ValidationSubmissionsTableProps, 'queryParams' | 'setQueryParams'>) => {
  const [selectedPartner, setSelectedPartner] = useState<string>(queryParams?.partnerId ?? '')
  const [partnerNameSearch, setPartnerNameSearch] = useState<string>('')
  const [debouncedSearch] = useDebounceValue(partnerNameSearch, 500)
  const { adminService } = useServices()

  const [{ data, isLoading }, { query }] = useQuery(adminService.queries.getPartnerList, {
    query: debouncedSearch,
    partnerIds: selectedPartner && !debouncedSearch ? [selectedPartner] : undefined
  })

  useEffect(() => {
    query({
      query: debouncedSearch,
      partnerIds: undefined
    })
  }, [debouncedSearch, query])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPartnerNameSearch(event.target.value)
  }

  const handleClearSearchValue = () => {
    setPartnerNameSearch('')
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (event.key === 'Enter') {
      setPartnerNameSearch((event.target as HTMLInputElement).value)
    }

    if (event.key === 'Escape') {
      setPartnerNameSearch('')
    }
  }

  const onPartnerChange = (partners: string[]) => {
    if (partners.length === 0) {
      setQueryParams({ partnerId: undefined })

      if (!debouncedSearch) {
        query({
          query: debouncedSearch,
          partnerIds: undefined
        })
      }
    }
    setSelectedPartner(partners.length === 0 ? '' : partners.filter(p => p !== selectedPartner)[0])
  }

  const onPartnersSearchButtonClick = () => {
    setQueryParams({ partnerId: selectedPartner })
  }

  return {
    selectedPartners: selectedPartner ? [selectedPartner] : [],
    partnerNameOptions: data?.map(p => ({ label: p.name, value: p.id })) || [],
    onPartnerChange,
    isPartnerNameFilterLoading: isLoading,
    partnerSearch: {
      onPartnerSearchChange: handleSearchChange,
      onClearPartnerSearchValue: handleClearSearchValue,
      partnerNameSearch,
      onPartnersSearchButtonClick,
      handleKeyPress
    }
  }
}
