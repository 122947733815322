import { Drawer, List, ListItem, Divider } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BankDetailsIcon } from '@percent/admin-dashboard/common/assets/images/card.svg'
import { ErrorView, Loader, PCTMenuItem } from '@percent/admin-dashboard/common/components'
import { ReactComponent as RegistriesIcon } from '@percent/admin-dashboard/common/assets/images/registries_icon.svg'
import { ReactComponent as SandboxIcon } from '@percent/admin-dashboard/common/assets/images/sandbox-icon.svg'
import { ReactComponent as AdminDashboardLogo } from '@percent/admin-dashboard/common/assets/images/admin_dashboard_logo.svg'
import { ReactComponent as PBPLogo } from '@percent/admin-dashboard/common/assets/images/pbpLogo.svg'
import { ReactComponent as NonprofitsLogo } from '@percent/admin-dashboard/common/assets/images/nonprofits.svg'
import { ReactComponent as ClaimsLogo } from '@percent/admin-dashboard/common/assets/images/claims.svg'
import { ReactComponent as ValidationsLogo } from '@percent/admin-dashboard/common/assets/images/heart.svg'
import { ReactComponent as PartnersLogo } from '@percent/admin-dashboard/common/assets/images/users.svg'
import { ReactComponent as MonitoringLogo } from '@percent/admin-dashboard/common/assets/images/monitoring.svg'
import { ReactComponent as AgentVerificationIcon } from '@percent/admin-dashboard/common/assets/images/agent_verification_icon.svg'
import { ReactComponent as ActivityIcon } from '@percent/admin-dashboard/common/assets/images/activity_icon.svg'
import { ReactComponent as ActivityEventsIcon } from '@percent/admin-dashboard/common/assets/images/activity_events_icon.svg'
import { ReactComponent as DisbursementsIcon } from '@percent/admin-dashboard/common/assets/images/disbursements_icon.svg'
import { ReactComponent as DonationRequestsIcon } from '@percent/admin-dashboard/common/assets/images/donation_requests_icon.svg'
import { ReactComponent as MatchRequestsIcon } from '@percent/admin-dashboard/common/assets/images/matching.svg'
import { ReactComponent as DonationsIcon } from '@percent/admin-dashboard/common/assets/images/donations_icon.svg'
import { ReactComponent as SettingsIcon } from '@percent/admin-dashboard/common/assets/images/settings.svg'
import { ReactComponent as VerificationsIcon } from '@percent/admin-dashboard/common/assets/images/verifications.svg'
import { environments } from '@percent/admin-dashboard/constants/environments'
import { environment } from '@percent/admin-dashboard/config'
import { useBadgeTotal } from '@percent/admin-dashboard/context/badgeTotal/useBadgeTotal/useBadgeTotal'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { ActivityCheckStatus, AgentVerificationState, EligibilityStatus } from '@percent/admin-dashboard/api/types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import style from './SideMenu.module.scss'

export function SideMenu() {
  const { t } = useTranslation()
  const { totalNumber, setTotalNumber } = useBadgeTotal()
  const { adminService, activityCheckService, agentVerificationService, eligibilityService, bankDetailsService } =
    useServices()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    registriesFeatureFlag,
    disbursementsFeatureFlag,
    bankDetailsReadFeatureFlag,
    donationRequestsFeatureFlag,
    donationMatchRequestsFeatureFlag,
    donationsFeatureFlag,
    validationSubmissionTabFeatureFlag,
    autoRejectionListFeatureFlag
  } = useFeatureFlag()

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      adminService.getClaims({
        accepted: false,
        rejected: false,
        pageSize: 0
      }),
      adminService.getValidations({ status: 'pending', pageSize: 0 }),
      activityCheckService.getActivityChecks({ status: ActivityCheckStatus.PENDING, pageSize: 0 }),
      agentVerificationService.getAgentVerifications({
        pageSize: 0,
        state: AgentVerificationState.PERCENT_ACTIONABLE
      }),
      adminService.getMonitoringSubscriptions({ status: 'pending', pageSize: 0 }),
      eligibilityService.getEligibilitySubscriptions({ status: EligibilityStatus.PENDING, pageSize: 0 }),
      bankDetailsService.getBankDetailsList({ statusFilter: 'pending' })
    ])
      .then(data => {
        setTotalNumber({
          ...totalNumber,
          totalPendingClaims: data[0].data.totalResults ?? 0,
          totalPendingValidations: data[1].data.totalResults ?? 0,
          totalPendingActivity: data[2].data.totalResults ?? 0,
          totalPendingAgentVerification: data[3].data.totalResults ?? 0,
          totalPendingMonititoring: data[4].data.totalResults ?? 0,
          totalPendingEligibility: data[5].data.totalResults ?? 0,
          totalPendingBankDetails: data[6].data.totalResults ?? 0
        })
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        setErrorMessage(APIErrorHandler(err?.response?.data?.error?.code))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Drawer variant="permanent" className={style.drawer}>
      <List>
        <ListItem className={style.percentLogo}>
          {environment === environments.SANDBOX ? <SandboxIcon /> : <AdminDashboardLogo />}
        </ListItem>
        <ListItem className={style.dividerListItem}>
          <Divider className={style.divider} />
        </ListItem>
        {(errorMessage && <ErrorView errorMessage={errorMessage} />) || (isLoading && <Loader />) || (
          <>
            <li className={style.sidebarCategory}>{t('menu.disbursements').toUpperCase()}</li>
            <PCTMenuItem
              name={t('typography.organizations')}
              to="/organizations"
              Logo={(props: any) => <NonprofitsLogo {...props} />}
            />
            <PCTMenuItem
              name={t('menu.claims')}
              to="/claims?accepted=false&rejected=false"
              Logo={(props: any) => <ClaimsLogo {...props} />}
              totalNumber={totalNumber.totalPendingClaims}
            />
            <PCTMenuItem name={t('menu.partners')} to="/partners" Logo={(props: any) => <PartnersLogo {...props} />} />
            {bankDetailsReadFeatureFlag && (
              <PCTMenuItem
                name="Bank details"
                to="/bank-details-reviews"
                Logo={(props: any) => <BankDetailsIcon {...props} />}
                totalNumber={totalNumber.totalPendingBankDetails}
              />
            )}
            {disbursementsFeatureFlag && (
              <PCTMenuItem
                name={t('menu.disbursements')}
                to="/disbursements"
                Logo={(props: any) => <DisbursementsIcon {...props} />}
              />
            )}
            {donationsFeatureFlag && (
              <PCTMenuItem
                name={t('menu.donations')}
                to="/donations"
                Logo={(props: any) => <DonationsIcon {...props} />}
              />
            )}
            {donationMatchRequestsFeatureFlag && (
              <PCTMenuItem
                name={t('menu.donationMatchRequests')}
                to="/donation-match-requests?status=processing"
                Logo={(props: any) => <MatchRequestsIcon {...props} />}
              />
            )}
            {donationRequestsFeatureFlag && (
              <PCTMenuItem
                name={t('menu.donationRequests')}
                to="/donation-requests"
                Logo={(props: any) => <DonationRequestsIcon {...props} />}
              />
            )}
            {registriesFeatureFlag && (
              <PCTMenuItem name="Registries" to="/registries" Logo={(props: any) => <RegistriesIcon {...props} />} />
            )}
            <li className={style.sidebarCategory}>{t('menu.checks')}</li>
            {validationSubmissionTabFeatureFlag && (
              <PCTMenuItem
                name={t('menu.verifications')}
                to="/validation-submissions"
                Logo={(props: any) => <VerificationsIcon {...props} />}
                // TODO: Update when API endpoint ready to filter by status=pending
                totalNumber={totalNumber.totalPendingValidations}
              />
            )}
            <PCTMenuItem
              name={t('menu.validations')}
              to="/validations?status=pending"
              Logo={(props: any) => <ValidationsLogo {...props} />}
              totalNumber={totalNumber.totalPendingValidations}
            />
            <PCTMenuItem
              name={t('menu.eligibility')}
              to="/eligibility?status=pending"
              Logo={(props: any) => <ActivityEventsIcon {...props} />}
              totalNumber={totalNumber.totalPendingEligibility}
            />
            <PCTMenuItem
              name={t('menu.compliance')}
              to="/compliance?status=pending"
              Logo={(props: any) => <MonitoringLogo {...props} />}
              totalNumber={totalNumber.totalPendingMonititoring}
            />
            <PCTMenuItem
              name={t('menu.agentVerification')}
              to="/agent-verification"
              Logo={(props: any) => <AgentVerificationIcon {...props} />}
              totalNumber={totalNumber.totalPendingAgentVerification}
            />
            <PCTMenuItem
              name={t('menu.activity')}
              to="/activity?status=pending"
              Logo={(props: any) => <ActivityIcon {...props} />}
              totalNumber={totalNumber.totalPendingActivity}
            />
            {autoRejectionListFeatureFlag && (
              <>
                <li className={style.sidebarCategory}>{t('menu.tooling')}</li>
                <PCTMenuItem
                  name={t('menu.autoRejectionList')}
                  to="/auto-rejection-list"
                  Logo={(props: any) => <SettingsIcon {...props} />}
                />
              </>
            )}
          </>
        )}
      </List>
      <div className={style.pbpLogo}>
        <PBPLogo width={120} />
      </div>
    </Drawer>
  )
}
