import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import _, { identity } from 'lodash'
import { Trans, useTranslation } from 'react-i18next'

import { ReassignDonationModalProps } from './ReassignDonationModal.types'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import {
  AcknowledgeModal,
  ActionModal,
  AsyncSelect,
  ConfirmationModal,
  FormField,
  Modal,
  Radio,
  Select,
  Spacer,
  TextInput
} from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import countries from '@percent/admin-dashboard/i18n/data/countries'
import { createShortLink } from '@percent/utility'
import i18n from '@percent/admin-dashboard/i18n/config'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { Organisation } from '@percent/admin-dashboard/api/types'
import { ReassignmentKind } from '@percent/admin-dashboard/api/actions/donations/donations.types'

const THROTTLING_TIME = 500
const reasons = {
  [ReassignmentKind.Unpayable]: [
    'Undeliverable - bank transfer fail',
    'Undeliverable - card payment fail',
    'Undeliverable - cheque returned',
    'Undeliverable - compliance fail',
    'Organisation was set incorrectly'
  ],
  [ReassignmentKind.Merge]: ['Duplicate organisation'],
  other: 'Other'
}

const createOptions = (items?: string[]) => {
  const allReasons = [...(items || []), reasons.other]

  return allReasons.map(reason => ({
    label: reason,
    value: reason
  }))
}

export function ReassignDonationModal({
  open,
  onClose,
  refresh,
  isReassignAll,
  sourceOrganisationName,
  serviceProps: { apiFunc, errorDialogState, success, setSuccessDialogState, setErrorDialogState, isLoading }
}: ReassignDonationModalProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()
  const [query, setQuery] = useState<string>('')
  const [options, setOptions] = useState(createOptions())
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [selectedOrganisationName, setSelectedOrganisationName] = useState<string | null | undefined>(null)
  const shortLanguage = i18n.language.split('-')[0]

  const [{ dataOrNull: organisationsData, isLoading: isOrganisationsLoading }, { query: organisationsQuery }] =
    useQueryList(adminService.getOrganisations, {
      pageSize: 25
    })

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      reassignmentKind: '',
      reassignedReason: '',
      customReason: '',
      organisationId: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        reassignmentKind: Yup.string().oneOf(Object.values(ReassignmentKind)).required(t('errorMessage.required')),
        reassignedReason: Yup.string().oneOf(Object.values(reasons).flat()).required(t('errorMessage.required')),
        organisationId: Yup.string().trim().required(t('errorMessage.required')),
        customReason: Yup.string()
          .nullable()
          .optional()
          .when('reassignedReason', {
            is: 'Other',
            then: Yup.string()
              .trim()
              .nullable()
              .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Custom reason' }))
              .max(100, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Custom reason' }))
              .required('Custom reason is required if "Other" is selected')
          })
      }),
    onSubmit: async ({ reassignedReason, organisationId, reassignmentKind, customReason }) => {
      await apiFunc({
        reason: reassignedReason === reasons.other ? customReason : reassignedReason,
        reassignmentKind: reassignmentKind as ReassignmentKind,
        organisationId
      })
    }
  })

  const {
    dirty,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    setValues,
    initialValues,
    handleSubmit,
    isValid,
    resetForm,
    values
  } = formik

  const handleCloseModal = () => {
    setShowConfirmationModal(false)
    onClose()
    refresh()
    resetForm()
    setSelectedOrganisationName(null)
    setSuccessDialogState(false)
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const resetReason = () => {
    if (!options.find(val => val.value === values.reassignedReason)) {
      setFieldValue('reassignedReason', initialValues.reassignedReason)
    }
  }

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('typography.id')}: ${organisation.registryId}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return organisationsData
      ? organisationsData.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [organisationsData, getOrganisationDescription])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrganisationsQuery = useCallback(
    _.debounce<any>((filter: string) => {
      organisationsQuery({ query: filter })
    }, THROTTLING_TIME),
    []
  )
  useEffect(() => {
    if (query) {
      debouncedOrganisationsQuery(query)
    }
  }, [query, debouncedOrganisationsQuery])

  useEffect(() => {
    setOptions(createOptions(reasons[values.reassignmentKind as ReassignmentKind]))
  }, [values.reassignmentKind])

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.reassignDonation.success.title')}
        description={
          <Trans
            i18nKey={
              isReassignAll
                ? `dialog.reassignAllDonations.${
                    values.reassignmentKind.toLowerCase() as 'merge' | 'unpayable'
                  }.success.description`
                : 'dialog.reassignDonation.success.description'
            }
            values={{ orgName: selectedOrganisationName }}
            components={{ b: <strong /> }}
          />
        }
        buttonText={t('button.close')}
        handleClose={handleCloseModal}
        viewTestId="success-donation-reassign-modal"
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.reassignDonation.error.title')}
        description={
          <Trans
            i18nKey={`dialog.${isReassignAll ? 'reassignAllDonations' : 'reassignDonation'}.error.description`}
            values={{ orgName: selectedOrganisationName }}
            components={{ b: <strong /> }}
          />
        }
        buttonText={t('button.close')}
        handleClose={handleOnErrorClose}
        viewTestId="error-donation-reassign-modal"
      />
    )

  return (
    <Modal open={open} onClose={handleCloseModal} aria-labelledby="reassign-donations-form-modal">
      {!success && !errorDialogState ? (
        showConfirmationModal ? (
          <ConfirmationModal
            title={t('dialog.reassignDonation.confirmation.title')}
            loading={isLoading}
            primaryButtonText={t('button.reassign')}
            primaryBtnTestId="confirm-donation-reassignment"
            secondaryButtonText={t('button.cancel')}
            handleSubmit={handleSubmit}
            handleClose={() => setShowConfirmationModal(false)}
          >
            <Trans
              i18nKey={
                isReassignAll
                  ? `dialog.reassignAllDonations.${
                      values.reassignmentKind.toLowerCase() as 'merge' | 'unpayable'
                    }.confirmation.description`
                  : 'dialog.reassignDonation.confirmation.description'
              }
              values={{ orgA: sourceOrganisationName, orgB: selectedOrganisationName }}
              components={{ b: <strong /> }}
            />
          </ConfirmationModal>
        ) : (
          <ActionModal
            title={t(`dialog.${isReassignAll ? 'reassignDonations' : 'reassignDonation'}.title`)}
            primaryButtonText={t('button.reassign')}
            secondaryButtonText={t('button.cancel')}
            type="submit"
            variant={isValid && dirty ? 'primary' : 'secondary'}
            disabled={!(isValid && dirty && !isLoading)}
            loading={isLoading}
            handleClose={handleCloseModal}
            handleSubmit={() => setShowConfirmationModal(true)}
            primaryBtnTestId="reassign-donation-submit-button"
            secondaryBtnTestId="reassign-donation-cancel-button"
          >
            <form>
              <FormField label="Type of reassignment" data-testid="unpayable-button" necessity="required">
                <Radio
                  name="kind"
                  value={ReassignmentKind.Unpayable}
                  label={t('typography.reassignmentKind.unpayable')}
                  checked={values.reassignmentKind === ReassignmentKind.Unpayable}
                  onChange={e => {
                    setFieldValue('reassignmentKind', e.target.value)
                    resetReason()
                  }}
                />
              </FormField>
              <Spacer size={4} axis="vertical" />
              <FormField data-testid="merge-button">
                <Radio
                  name="kind"
                  value={ReassignmentKind.Merge}
                  label={t('typography.reassignmentKind.merge')}
                  checked={values.reassignmentKind === ReassignmentKind.Merge}
                  onChange={e => {
                    setFieldValue('reassignmentKind', e.target.value)
                    resetReason()
                  }}
                />
              </FormField>
              <Spacer size={4} axis="vertical" />
              <FormField label={t('typography.newOrganisation')} data-testid="organisationId" necessity="required">
                <AsyncSelect
                  name="organisationId"
                  placeholder={t('typography.search.organisation')}
                  onChange={e => {
                    setValues({
                      ...values,
                      organisationId: e?.value || ''
                    })
                    setSelectedOrganisationName(e?.label)
                  }}
                  options={searchResults}
                  setQuery={e => {
                    setQuery(e)
                    setFieldTouched('organisationId')
                  }}
                  query={query}
                  loading={isOrganisationsLoading}
                  loadingText="loading"
                  noResultsFoundText={t('errorMessage.noResult')}
                  data-testid="organisationSearch"
                />
              </FormField>
              {values.reassignmentKind && (
                <>
                  <Spacer size={4} axis="vertical" />
                  <FormField
                    disabled={!values.reassignmentKind}
                    label={t('dialog.reassignDonation.reassignedReason')}
                    data-testid="reassignedReason"
                    necessity="required"
                  >
                    <Select
                      placeholder={t('dialog.reassignDonation.reassignedReason')}
                      options={options}
                      onChange={({ value }) => setFieldValue('reassignedReason', value)}
                    />
                  </FormField>
                </>
              )}
              {values.reassignedReason === reasons.other && (
                <>
                  <Spacer size={4} axis="vertical" />
                  <FormField
                    label={t('dialog.reassignDonation.customReason')}
                    necessity="required"
                    status={touched.customReason && errors.customReason ? 'danger' : 'default'}
                    statusMessage={errors.customReason || ''}
                    data-testid="customReason"
                  >
                    <TextInput
                      name="customReason"
                      value={values.customReason || ''}
                      placeholder={t('dialog.reassignDonation.customReason')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormField>
                </>
              )}
            </form>
          </ActionModal>
        )
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
