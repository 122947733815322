import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'

import { Icon, Tooltip } from '@percent/lemonade'
import styles from './MatchingDocumentsDetails.module.scss'
import { MatchingDetailsProps, MatchingDocumentsDetailsProps } from './MatchingDocumentsDetails.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

const cx = classNames.bind(styles)

function MatchingDetail({ translationKey, tooltipKey, isFound, testId }: Readonly<MatchingDetailsProps>) {
  const { t } = useTranslation()

  return (
    <Tooltip content={t(tooltipKey)}>
      <div
        className={cx({
          detailWrapper: true,
          found: isFound,
          notFound: !isFound
        })}
        data-testid={testId}
      >
        <span>{t(translationKey)}</span>
        <Icon name={isFound ? 'approve' : 'warning'} color={isFound ? 'success400' : 'warning400'} size={6} />
      </div>
    </Tooltip>
  )
}

export function MatchingDocumentsDetails({ documents }: Readonly<MatchingDocumentsDetailsProps>) {
  if (documents.length === 0) return null

  const firstNameFound = documents.some(d => d.firstNameFound)
  const lastNameFound = documents.some(d => d.lastNameFound)
  const organisationNameFound = documents.some(d => d.organisationNameFound)

  const getNameDetailTooltipKey = (): LocaleKey => {
    if (firstNameFound && lastNameFound) return 'typography.bothNamesFoundTooltip'

    if (firstNameFound) return 'typography.firstNameFoundTooltip'

    if (lastNameFound) return 'typography.lastNameFoundTooltip'

    return 'typography.bothNamesNotFoundTooltip'
  }

  return (
    <div className={styles.container} data-testid="matching-documents-details">
      <MatchingDetail
        translationKey="typography.name"
        tooltipKey={getNameDetailTooltipKey()}
        isFound={firstNameFound || lastNameFound}
        testId="name-detail"
      />
      <MatchingDetail
        translationKey="typography.orgName"
        tooltipKey={organisationNameFound ? 'typography.orgNameFoundTooltip' : 'typography.orgNameNotFoundTooltip'}
        isFound={organisationNameFound}
        testId="org-name-detail"
      />

      <MatchingDetail
        translationKey="typography.role"
        tooltipKey="typography.roleTooltip"
        isFound={false}
        testId="role-detail"
      />
      <MatchingDetail
        translationKey="typography.docType"
        tooltipKey="typography.docTypeTooltip"
        isFound={false}
        testId="doc-type-detail"
      />
      <MatchingDetail
        translationKey="typography.fraud"
        tooltipKey="typography.fraudTooltip"
        isFound={false}
        testId="fraud-detail"
      />
    </div>
  )
}
