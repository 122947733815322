import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'

import { ValidationSubmissionsTableProps } from '../ValidationSubmissionsTable.types'

import { StatusType } from '@percent/domain/verifications'

export const useStatusesFilter = ({
  queryParams,
  setQueryParams
}: Pick<ValidationSubmissionsTableProps, 'queryParams' | 'setQueryParams'>) => {
  const { t } = useTranslation()

  const statusesOptions = [
    { label: t('table.pending'), value: StatusType.Pending },
    { label: t('table.approved'), value: StatusType.Succeeded },
    { label: t('table.processed'), value: StatusType.Processed },
    { label: t('table.rejected'), value: StatusType.Failed }
  ]

  const onStatusChange = useCallback(
    (statuses: string[]) =>
      setQueryParams({
        statuses
      }),
    [setQueryParams]
  )

  return {
    statusesOptions,
    onStatusChange,
    selectedStatuses: queryParams?.statuses || []
  }
}
