import { useContext, useMemo } from 'react'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'

import {
  eligibilityPartner,
  splitSdkConfig,
  updateEmailFlag,
  bankDetailsReadFlag,
  bankDetailsSubmitFlag,
  bankDetailsReviewApprovalFlag,
  bankDetailsReviewDeleteFlag,
  paymentMethodsReadFlag,
  paymentMethodsSubmitFlag,
  markOrganisationAsUnableToPayFlag,
  registriesFlag,
  disbursementsFlag,
  donationRequestsFlag,
  donationsFlag,
  organizationViewFlag,
  orgBrandDetailsFlag,
  editRegistrationStatusFlag,
  registriesRequiredTypesFlag,
  editOfficialDetailsFlag,
  editBrandDetailsFlag,
  editContactstionDetailsFlag,
  editLocationDetailsFlag,
  addOrgFlag,
  unclaimOrgFlag,
  addOrgFileFlag,
  claimsDomainMatchingTemporaryFixFlag,
  donationMatchRequestsFlag,
  agentVerificationManualFradulentRejectionFlag,
  educationSubtagsNoneOfTheAboveFlag,
  unrejectClaimFlag,
  editPartnerFlag,
  batchUpdateOrganisationsFlag,
  autoRejectionListFlag,
  validationSubmissionTabFlag,
  reassignDonationFlag,
  newEduStageSubtagFlag,
  registriesSupportedTypesFlag,
  dynamicAgentVerificationFlag,
  countrySpecificDataFlag
} from '@percent/admin-dashboard/app/providers/SplitSdkProvider'

export function useFeatureFlag() {
  const {
    [eligibilityPartner]: { treatment: eligibilitiesPartnerTreatment },
    [updateEmailFlag]: { treatment: updateEmailTreatment },
    [registriesFlag]: { treatment: registriesTreatment },
    [registriesRequiredTypesFlag]: { treatment: registriesRequiredTypesTreatment },
    [disbursementsFlag]: { treatment: disbursementsTreatment },
    [bankDetailsReadFlag]: { treatment: bankDetailsReadTreatment },
    [bankDetailsSubmitFlag]: { treatment: bankDetailsSubmitTreatment },
    [bankDetailsReviewApprovalFlag]: { treatment: bankDetailsReviewApprovalTreatment },
    [bankDetailsReviewDeleteFlag]: { treatment: bankDetailsReviewDeleteTreatment },
    [paymentMethodsReadFlag]: { treatment: paymentMethodsReadTreatment },
    [paymentMethodsSubmitFlag]: { treatment: paymentMethodsSubmitTreatment },
    [markOrganisationAsUnableToPayFlag]: { treatment: markOrganisationAsUnableToPayTreatment },
    [donationRequestsFlag]: { treatment: donationRequestsTreatment },
    [donationMatchRequestsFlag]: { treatment: donationMatchRequestsTreatment },
    [donationsFlag]: { treatment: donationsTreatment },
    [reassignDonationFlag]: { treatment: reassignDonationTreatment },
    [organizationViewFlag]: { treatment: organizationViewTreatment },
    [orgBrandDetailsFlag]: { treatment: orgBrandDetailsTreatment },
    [editRegistrationStatusFlag]: { treatment: editRegistrationStatusTreatment },
    [editOfficialDetailsFlag]: { treatment: editOfficialDetailsTreatment },
    [editBrandDetailsFlag]: { treatment: editBrandDetailsTreatment },
    [editContactstionDetailsFlag]: { treatment: editContactsDetailsTreatment },
    [editLocationDetailsFlag]: { treatment: editLocationDetailsTreatment },
    [addOrgFlag]: { treatment: addOrgTreatment },
    [unclaimOrgFlag]: { treatment: unclaimOrgTreatment },
    [addOrgFileFlag]: { treatment: addOrgFileTreatment },
    [claimsDomainMatchingTemporaryFixFlag]: { treatment: claimsDomainMatchingTemporaryFixTreatment },
    [agentVerificationManualFradulentRejectionFlag]: { treatment: agentVerificationManualFradulentRejectionTreatment },
    [educationSubtagsNoneOfTheAboveFlag]: { treatment: educationSubtagsNoneOfTheAboveTreatment },
    [unrejectClaimFlag]: { treatment: unrejectClaimTreatment },
    [editPartnerFlag]: { treatment: editPartnerTreatment },
    [batchUpdateOrganisationsFlag]: { treatment: batchUpdateOrganisationsTreatment },
    [autoRejectionListFlag]: { treatment: autoRejectionListTreatment },
    [validationSubmissionTabFlag]: { treatment: validationSubmissionTabTreatment },
    [newEduStageSubtagFlag]: { treatment: newEduStageSubtagTreatment },
    [registriesSupportedTypesFlag]: { treatment: registriesSupportedTypesTreatment },
    [dynamicAgentVerificationFlag]: { treatment: dynamicAgentVerificationTreatment },
    [countrySpecificDataFlag]: { treatment: countrySpecificDataTreatment }
  } = useTreatments([
    eligibilityPartner,
    updateEmailFlag,
    registriesFlag,
    disbursementsFlag,
    bankDetailsReadFlag,
    bankDetailsSubmitFlag,
    bankDetailsReviewApprovalFlag,
    bankDetailsReviewDeleteFlag,
    paymentMethodsReadFlag,
    paymentMethodsSubmitFlag,
    markOrganisationAsUnableToPayFlag,
    donationRequestsFlag,
    donationMatchRequestsFlag,
    donationsFlag,
    reassignDonationFlag,
    organizationViewFlag,
    orgBrandDetailsFlag,
    editRegistrationStatusFlag,
    editOfficialDetailsFlag,
    editBrandDetailsFlag,
    editContactstionDetailsFlag,
    editLocationDetailsFlag,
    addOrgFlag,
    unclaimOrgFlag,
    addOrgFileFlag,
    claimsDomainMatchingTemporaryFixFlag,
    agentVerificationManualFradulentRejectionFlag,
    educationSubtagsNoneOfTheAboveFlag,
    unrejectClaimFlag,
    editPartnerFlag,
    batchUpdateOrganisationsFlag,
    autoRejectionListFlag,
    validationSubmissionTabFlag,
    registriesRequiredTypesFlag,
    newEduStageSubtagFlag,
    registriesSupportedTypesFlag,
    dynamicAgentVerificationFlag,
    countrySpecificDataFlag
  ])
  const { isReady, isTimedout } = useContext(SplitContext)

  const featureFlagValue = useMemo(() => {
    const eligibilitiesPartnerFeatureFlag =
      (isReady && eligibilitiesPartnerTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_eligibility_partner === 'on')
    const updateEmailFeatureFlag =
      (isReady && updateEmailTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_update_email === 'on')
    const registriesFeatureFlag =
      (isReady && registriesTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_registries === 'on')
    const registriesRequiredTypesFeatureFlag =
      (isReady && registriesRequiredTypesTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_registries_required_types === 'on')
    const bankDetailsReadFeatureFlag =
      (isReady && bankDetailsReadTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_bank_details_read === 'on')
    const bankDetailsSubmitFeatureFlag =
      (isReady && bankDetailsSubmitTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_bank_details_submit === 'on')
    const bankDetailsReviewApprovalFeatureFlag =
      (isReady && bankDetailsReviewApprovalTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_bank_details_review_approval === 'on')
    const bankDetailsReviewDeleteFeatureFlag =
      (isReady && bankDetailsReviewDeleteTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_bank_details_review_delete === 'on')
    const paymentMethodsReadFeatureFlag =
      (isReady && paymentMethodsReadTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_payment_methods_read === 'on')
    const paymentMethodsSubmitFeatureFlag =
      (isReady && paymentMethodsSubmitTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_payment_methods_submit === 'on')
    const markOrganisationAsUnableToPayFeatureFlag =
      (isReady && markOrganisationAsUnableToPayTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_mark_organisation_unpayable === 'on')
    const disbursementsFeatureFlag =
      (isReady && disbursementsTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_disbursements === 'on')
    const donationRequestsFeatureFlag =
      (isReady && donationRequestsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_donation_requests === 'on')
    const donationMatchRequestsFeatureFlag =
      (isReady && donationMatchRequestsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_donation_match_requests === 'on')
    const donationsFeatureFlag =
      (isReady && donationsTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_donations_table === 'on')
    const reassignDonationFeatureFlag =
      (isReady && reassignDonationTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_reassign_donation === 'on')
    const organizationViewFeatureFlag =
      (isReady && organizationViewTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_organization_view === 'on')
    const orgBrandDetailsFeatureFlag =
      (isReady && orgBrandDetailsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_org_brand_details === 'on')
    const addOrgFeatureFlag =
      (isReady && addOrgTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_add_org === 'on')
    const editRegistrationStatusFeatureFlag =
      (isReady && editRegistrationStatusTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_edit_registration_status === 'on')
    const editOfficialDetailsFeatureFlag =
      (isReady && editOfficialDetailsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_edit_official_details === 'on')
    const editBrandDetailsFeatureFlag =
      (isReady && editBrandDetailsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_edit_brand_details === 'on')
    const editContactsDetailsFeatureFlag =
      (isReady && editContactsDetailsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_edit_contacts_details === 'on')
    const editLocationDetailsFeatureFlag =
      (isReady && editLocationDetailsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_edit_location_details === 'on')
    const unclaimOrgFeatureFlag =
      (isReady && unclaimOrgTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_edit_unclaim_org === 'on')
    const addOrgFileFeatureFlag =
      (isReady && addOrgFileTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_add_org_file === 'on')
    const claimsDomainMatchingTemporaryFixFeatureFlag =
      (isReady && claimsDomainMatchingTemporaryFixTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_claims_domain_matching_temp_fix === 'on')
    const agentVerificationManualFradulentRejectionFeatureFlag =
      (isReady && agentVerificationManualFradulentRejectionTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_agent_verification_manual_fraudlent_rejection === 'on')
    const educationSubtagsNoneOfTheAboveFeatureFlag =
      (isReady && educationSubtagsNoneOfTheAboveTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_none_of_the_above_edu_subtags === 'on')
    const unrejectClaimFeatureFlag =
      (isReady && unrejectClaimTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_unreject_claim === 'on')
    const editPartnerFeatureFlag =
      (isReady && editPartnerTreatment === 'on') || (isTimedout && splitSdkConfig.features.ad_edit_partner === 'on')
    const batchUpdateOrganisationsFeatureFlag =
      (isReady && batchUpdateOrganisationsTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_batch_update_organisations === 'on')
    const autoRejectionListFeatureFlag =
      (isReady && autoRejectionListTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_auto_rejection_list === 'on')
    const validationSubmissionTabFeatureFlag =
      (isReady && validationSubmissionTabTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_validation_submission_tab === 'on')
    const newEduStageSubtagFeatureFlag =
      (isReady && newEduStageSubtagTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_new_edu_subtags === 'on')
    const registriesSupportedTypesFeatureFlag =
      (isReady && registriesSupportedTypesTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_registries_supported_types === 'on')
    const dynamicAgentVerificationFeatureFlag =
      (isReady && dynamicAgentVerificationTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_dynamic_agent_verification === 'on')

    const countrySpecificDataFeatureFlag =
      (isReady && countrySpecificDataTreatment === 'on') ||
      (isTimedout && splitSdkConfig.features.ad_country_specific_data === 'on')

    return {
      eligibilitiesPartnerFeatureFlag,
      updateEmailFeatureFlag,
      registriesFeatureFlag,
      disbursementsFeatureFlag,
      bankDetailsReadFeatureFlag,
      bankDetailsSubmitFeatureFlag,
      bankDetailsReviewApprovalFeatureFlag,
      bankDetailsReviewDeleteFeatureFlag,
      paymentMethodsReadFeatureFlag,
      paymentMethodsSubmitFeatureFlag,
      markOrganisationAsUnableToPayFeatureFlag,
      donationRequestsFeatureFlag,
      donationMatchRequestsFeatureFlag,
      donationsFeatureFlag,
      reassignDonationFeatureFlag,
      organizationViewFeatureFlag,
      orgBrandDetailsFeatureFlag,
      editRegistrationStatusFeatureFlag,
      registriesRequiredTypesFeatureFlag,
      editOfficialDetailsFeatureFlag,
      editBrandDetailsFeatureFlag,
      editContactsDetailsFeatureFlag,
      editLocationDetailsFeatureFlag,
      addOrgFeatureFlag,
      unclaimOrgFeatureFlag,
      addOrgFileFeatureFlag,
      claimsDomainMatchingTemporaryFixFeatureFlag,
      agentVerificationManualFradulentRejectionFeatureFlag,
      educationSubtagsNoneOfTheAboveFeatureFlag,
      unrejectClaimFeatureFlag,
      editPartnerFeatureFlag,
      batchUpdateOrganisationsFeatureFlag,
      autoRejectionListFeatureFlag,
      validationSubmissionTabFeatureFlag,
      newEduStageSubtagFeatureFlag,
      registriesSupportedTypesFeatureFlag,
      dynamicAgentVerificationFeatureFlag,
      countrySpecificDataFeatureFlag,
      isReady,
      isTimedout
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, isTimedout])

  return featureFlagValue
}
