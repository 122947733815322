import Fuse from 'fuse.js'
import { ChangeEvent, EventHandler, useCallback, useEffect, useMemo, useState, KeyboardEvent } from 'react'
import { Alpha3Code } from 'i18n-iso-countries'

import { ValidationSubmissionsTableProps } from '../ValidationSubmissionsTable.types'

import { useCountries } from '@percent/admin-dashboard/common/hooks'

type CountrySearchOption = { label: string; value: Alpha3Code }

const searchOptions = {
  includeMatches: true,
  ignoreLocation: true,
  keys: [{ name: 'label', weight: 100 }],
  threshold: 0.1
}

export const useCountryFilter = ({
  queryParams,
  setQueryParams
}: Pick<ValidationSubmissionsTableProps, 'queryParams' | 'setQueryParams'>) => {
  const countries = useCountries()

  const [searchValue, setSearchValue] = useState<string>('')
  const [selectedCountries, setSelectedCountries] = useState<Alpha3Code[]>(queryParams.countryCodes || [])
  const [searchContriesResult, setSearchContriesResult] = useState<CountrySearchOption[]>([])

  const countryOptions: CountrySearchOption[] = useMemo(
    () =>
      countries.map(option => ({
        label: option.name,
        value: option.code as Alpha3Code
      })),
    [countries]
  )

  const countriesSearch = countryOptions && new Fuse(countryOptions, searchOptions)

  const onCountryChange = useCallback(
    (values: Alpha3Code[]) => {
      setSelectedCountries(values)
    },
    [setSelectedCountries]
  )

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleClearSearchValue = () => {
    setSearchValue('')
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (event.key === 'Enter') {
      setSearchValue((event.target as HTMLInputElement).value)
    }

    if (event.key === 'Escape') {
      setSearchValue('')
    }
  }

  useEffect(() => {
    const results = countriesSearch.search(searchValue)

    const options = searchValue ? results.map(({ item }) => item) : countryOptions

    setSearchContriesResult([
      ...selectedCountries.map(option => ({
        label: countries?.find(({ code }) => code === option)?.name || '',
        value: option
      })),
      ...options.filter(country => !selectedCountries.find(value => country.value === value))
    ])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue])

  const handleCountrySearchButtonClick = useCallback(() => {
    setQueryParams({
      ...queryParams,
      ...(selectedCountries.length ? { countryCodes: selectedCountries } : { countryCodes: [] })
    })
  }, [queryParams, selectedCountries, setQueryParams])

  return {
    countryOptions: searchContriesResult,
    selectedCountries,
    onCountryChange,
    countrySearch: {
      onCountrySearchChange: handleSearchChange,
      onClearCountrySearchValue: handleClearSearchValue,
      countrySearchValue: searchValue,
      onCountrySearchButtonClick: handleCountrySearchButtonClick,
      handleKeyPress
    }
  }
}
