import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'

import { ValidationSubmissionsTableProps } from '../ValidationSubmissionsTable.types'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'

export const useProgramNameFilter = ({
  queryParams,
  setQueryParams
}: Pick<ValidationSubmissionsTableProps, 'queryParams' | 'setQueryParams'>) => {
  const { t } = useTranslation()

  const { validationSubmissionsService } = useServices()

  const { partnerId } = queryParams

  const [{ data: configurations }, { query }] = useQuery(
    validationSubmissionsService.getValidationSubmissionConfigurations,
    { partnerId }
  )

  const onProgramChange = useCallback(
    (programs: string[]) =>
      setQueryParams({
        configurationIds: programs
      }),
    [setQueryParams]
  )

  useEffect(() => {
    if (queryParams.partnerId) {
      query({ partnerId })
    }
    onProgramChange([])
  }, [queryParams.partnerId, partnerId, query, onProgramChange])

  return {
    programNameOptions:
      configurations?.map(configuration => ({
        label: configuration.name || t('typography.unnamedProgram'),
        value: configuration.id
      })) || [],
    onProgramChange,
    selectedPrograms: queryParams.configurationIds || [],
    isSelectedProgramsFilterDisabled: !queryParams.partnerId
  }
}
