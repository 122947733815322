import { Paper, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './BankDetailsHeader.module.scss'
import { StatusBadge } from '@percent/admin-dashboard/app/bankDetails/statusBadge/StatusBadge'
import { BankDetailsHeaderProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsHeader/BankDetailsHeader.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { Copy } from '@percent/lemonade'

export function BankDetailsHeader({
  organizationName,
  status,
  reference,
  setDialogState,
  setFormStatus,
  disabledApprove
}: BankDetailsHeaderProps) {
  const { bankDetailsReviewApprovalFeatureFlag, bankDetailsReviewDeleteFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
      <Grid item xs={12} className={styles.nonprofitDetail}>
        <div className={styles.ellipsisParent}>
          <div className={styles.ellipsisContainer}>
            <div className={styles.ellipsisText}>
              <Typography variant="h6" className={styles.heading}>
                {organizationName}
              </Typography>
              <Box className={styles.additionalInfo}>
                <ul className={styles.headerList}>
                  <li>
                    <strong>{t('typography.bankDetailsReviewRequestReference')}</strong>&nbsp;
                    <Copy text={reference}>{reference}</Copy>
                  </li>
                  <Divider orientation="vertical" flexItem />
                  <li>
                    <StatusBadge status={status} />
                  </li>
                </ul>
              </Box>
            </div>
            {status === 'pending' && bankDetailsReviewApprovalFeatureFlag && (
              <div className={styles.buttonsContainer}>
                <PercentButton
                  title={t('button.approve')}
                  className={styles.approveButton}
                  disabled={disabledApprove}
                  testId="approve-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('approved')
                  }}
                />
                <PercentButton
                  title={t('button.reject')}
                  testId="reject-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('rejected')
                  }}
                  className={styles.rejectButton}
                />
              </div>
            )}
            {status === 'approved' && bankDetailsReviewDeleteFeatureFlag && (
              <div className={styles.buttonsContainer}>
                <PercentButton
                  title={t('button.remove')}
                  testId="remove-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('deleted')
                  }}
                  className={styles.rejectButton}
                />
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
