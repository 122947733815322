import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { BankDetailsHeader } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsHeader/BankDetailsHeader'
import { BankDetailsDialog } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDialog/BankDetailsDialog'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import styles from './BankDetailsView.module.scss'
import { useBreadcrumbsData } from '@percent/admin-dashboard/app/Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { Loader } from '@percent/admin-dashboard/common/components'
import { BankDetailsDocumentErrorDialog } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDocument/BankDetailsDocumentErrorDialog'
import { WireTransferType } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'
import { ReactComponent as PencilSVG } from '@percent/admin-dashboard/common/assets/images/pencil.svg'
import { BankDetailsFormDialog } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsFormDialog/BankDetailsFormDialog'
import { FlagAvatar } from '@percent/lemonade'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function BankDetailsView() {
  const { bankDetailsSubmitFeatureFlag } = useFeatureFlag()
  const { bankDetailsService } = useServices()
  const { id } = useParams<{ id: string }>()
  const { setPath } = useBreadcrumbsData()
  const { t } = useTranslation()
  const [dialogState, setDialogState] = useState(false)
  const [dialogFormState, setDialogFromState] = useState(false)
  const [formStatus, setFormStatus] = useState<null | 'approved' | 'rejected' | 'deleted'>(null)

  const [{ data, isLoading }, { refresh }] = useQuery(bankDetailsService.getBankDetailsById, { id })
  const [{ data: bankDetailsDocumentData, error }, { refresh: refreshDocumentUrl }] = useQuery(
    bankDetailsService.getBankDetailsDocumentByBankDetailsId,
    { id }
  )

  const disableApprove =
    !data?.bankAccountDetails && data?.requiredBankAccountDetails?.type === WireTransferType.DOMESTIC_WITH_BIC

  const enableEdit =
    disableApprove ||
    (data?.requiredBankAccountDetails?.type === WireTransferType.DOMESTIC_WITH_BIC && data?.status === 'pending')

  useEffect(() => setPath(data?.organisationName), [setPath, data])

  const handleOpenDocument = async () => {
    await refreshDocumentUrl()

    return window.open(bankDetailsDocumentData?.url, '_blank', 'noreferrer')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <BankDetailsHeader
        organizationName={data?.organisationName}
        status={data?.status}
        reference={data?.reference}
        setDialogState={setDialogState}
        setFormStatus={setFormStatus}
        disabledApprove={disableApprove}
      />
      <BankDetailsDialog
        open={dialogState}
        onClose={() => setDialogState(false)}
        organisationId={data?.organisationId}
        organisationName={data?.organisationName}
        formStatus={formStatus}
        refresh={refresh}
      />
      <BankDetailsFormDialog
        organisationId={data?.organisationId}
        organisationName={data?.organisationName}
        open={dialogFormState}
        onClose={() => setDialogFromState(false)}
        bankDetailsData={data}
        refresh={refresh}
      />
      <BankDetailsDocumentErrorDialog error={!!error} />
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h6">{t('typography.bankDetails')}</Typography>
          {enableEdit && bankDetailsSubmitFeatureFlag && (
            <ListItemButton
              className={styles.editButton}
              title={disableApprove ? t(`button.addDetails`) : t(`button.edit`)}
              onClick={() => setDialogFromState(true)}
              icon={<PencilSVG />}
              testId="bank-details-edit-button"
            />
          )}
        </Grid>
        <ul className={styles.bankDetailsList}>
          <li>
            <p>{t('typography.accountHolderName')}</p>
            <span data-testId="holder-value">
              {data?.bankAccountDetails?.holderName || t('typography.infoNotAdded')}
            </span>
          </li>
          {data?.bankAccountDetails?.wireTransferDetails?.type === WireTransferType.DOMESTIC && (
            <>
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.bankCodeLabel}</p>
                <span data-testId="bankcode-value">{data?.bankAccountDetails?.wireTransferDetails?.bankCode}</span>
              </li>
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.accountNumberLabel}</p>
                <span data-testId="account-value">{data?.bankAccountDetails?.wireTransferDetails?.accountNumber}</span>
              </li>
            </>
          )}
          {data?.bankAccountDetails?.wireTransferDetails?.type === WireTransferType.IBAN && (
            <>
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.bicLabel}</p>
                <span data-testId="bic-value">{data?.bankAccountDetails?.wireTransferDetails?.bic}</span>
              </li>
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.ibanLabel}</p>
                <span data-testId="iban-value">{data?.bankAccountDetails?.wireTransferDetails?.iban}</span>
              </li>
            </>
          )}
          {(data?.requiredBankAccountDetails?.type || data?.bankAccountDetails?.wireTransferDetails?.type) ===
            WireTransferType.DOMESTIC_WITH_BIC && (
            <>
              <li>
                <p>
                  {data?.bankAccountDetails?.wireTransferDetails?.accountNumberLabel || t('typography.accountNumber')}
                </p>
                <span data-testId="account-value">
                  {data?.bankAccountDetails?.wireTransferDetails?.accountNumber || t('typography.infoNotAdded')}
                </span>
              </li>
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.bicLabel || t('typography.bicSwiftCode')}</p>
                <span data-testId="bic-value">
                  {data?.bankAccountDetails?.wireTransferDetails?.bic || t('typography.infoNotAdded')}
                </span>
              </li>
              {data?.bankAccountDetails?.wireTransferDetails?.bankCode && (
                <li>
                  <p>{data?.bankAccountDetails?.wireTransferDetails?.bankCodeLabel}</p>
                  <span data-testId="bankcode-value">{data?.bankAccountDetails?.wireTransferDetails?.bankCode}</span>
                </li>
              )}
              {data?.bankAccountDetails?.wireTransferDetails?.branchCode && (
                <li>
                  <p>{data?.bankAccountDetails?.wireTransferDetails?.branchCodeLabel}</p>
                  <span data-testId="branch-value">{data?.bankAccountDetails?.wireTransferDetails?.branchCode}</span>
                </li>
              )}
            </>
          )}

          <li>
            <p>{t('typography.proof')}</p>
            <Typography
              className={styles.link}
              onClick={() => {
                if (!error) {
                  handleOpenDocument()
                }
              }}
            >
              {t('typography.viewDocument')}
            </Typography>
          </li>
        </ul>
      </Grid>
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h6">{t('typography.otherDetails')}</Typography>
        </Grid>
        <ul className={styles.bankDetailsList}>
          <li>
            <p>{t('typography.country')}</p>
            <span data-testId="country-value">
              <FlagAvatar code={data?.countryCode} />
              {`${getCountryFromAlpha3(data?.countryCode)}`}
            </span>
          </li>
          {data?.organisationAddress && (
            <li>
              <p>{t('typography.address')}</p>
              <span data-testId="address-value">
                {data?.organisationAddress.split(',').map((address: string, index: number) => (
                  <>
                    {address}
                    {index !== data.organisationAddress.split(',').length - 1 && <br />}
                  </>
                ))}
              </span>
            </li>
          )}
          <li>
            <p>{t('typography.contact')}</p>
            <span data-testId="email-value">{data?.claimer.email}</span>
          </li>
        </ul>
      </Grid>
    </>
  )
}
