import { WireTransferType } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'

type FieldsBase = {
  errorMessage: string
  hint: string
  label: string
  regex: string
  required: boolean
}

type MockBankDetailsResponse = {
  totalResults: number
  data: {
    organisationId: string
    countryCode: string
    organisationName: string
    claimer: { email: string }
    status: string
    requestDate: string
    reference: string
    document: { url: string }
    bankAccountDetails?: {
      holderName: string
      wireTransferDetails:
        | {
            type: WireTransferType.DOMESTIC
            bankCode: string
            accountNumber: string
          }
        | {
            type: WireTransferType.IBAN
            bic: string
            iban: string
          }
        | {
            type: WireTransferType.DOMESTIC_WITH_BIC
            accountNumber: string
            bicCode: string
          }
    }
    requiredBankAccountDetails?: {
      fields: {
        accountNumber: FieldsBase
        bankCode: FieldsBase
        bic: FieldsBase
        branchCode: FieldsBase
      }
      type: WireTransferType.DOMESTIC_WITH_BIC
    }
  }[]
}

export const mockDomesticBankDetailsListResponse: MockBankDetailsResponse = {
  totalResults: 3,
  data: [
    {
      organisationId: '123',
      countryCode: 'NOR',
      organisationName: 'Test NOR',
      claimer: { email: 'test@nor.com' },
      status: 'pending',
      requestDate: '21 Dec 2022, 15:10',
      reference: 'AV73SD',
      document: { url: 'https://dyingwithdignity.ca/wp-content/uploads/2022/03/DWDC_Wire_Transfer_Information.pdf' },
      bankAccountDetails: {
        holderName: 'NOR Account Name',
        wireTransferDetails: {
          type: WireTransferType.DOMESTIC,
          bankCode: '000',
          accountNumber: '000 000 000'
        }
      }
    },
    {
      countryCode: 'DMA',
      organisationName: 'Test DMA',
      claimer: { email: 'test@dma.com' },
      status: 'pending',
      requestDate: '26 Dec 2022, 14:10',
      reference: '7S2MKR',
      organisationId: '910',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      bankAccountDetails: {
        holderName: 'DMA Account Name',
        wireTransferDetails: {
          type: WireTransferType.DOMESTIC,
          bankCode: '222',
          accountNumber: '222 222'
        }
      }
    },
    {
      countryCode: 'IRN',
      organisationName: 'Test IRN',
      claimer: { email: 'test@irn.com' },
      status: 'rejected',
      requestDate: '09 Dec 2022, 09:10',
      reference: 'KUH2ZD',
      organisationId: '678',
      document: { url: 'https://dyingwithdignity.ca/wp-content/uploads/2022/03/DWDC_Wire_Transfer_Information.pdf' },
      bankAccountDetails: {
        holderName: 'IRN Account Name',
        wireTransferDetails: {
          type: WireTransferType.IBAN,
          bic: '333',
          iban: '333 333'
        }
      }
    }
  ]
}

export const mockIBANBankDetailsListResponse: MockBankDetailsResponse = {
  totalResults: 1,
  data: [
    {
      countryCode: 'CUB',
      organisationName: 'Test CUB',
      claimer: { email: 'test@cub.com' },
      status: 'approved',
      requestDate: '26 Dec 2022, 11:10',
      reference: '97sdm2',
      organisationId: '456',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      bankAccountDetails: {
        holderName: 'CUB Account Name',
        wireTransferDetails: {
          type: WireTransferType.IBAN,
          bic: '111',
          iban: '111 111'
        }
      }
    }
  ]
}

export const mockDomesticWithBICBankDetailsListResponse: MockBankDetailsResponse = {
  totalResults: 1,
  data: [
    {
      countryCode: 'CUB',
      organisationName: 'Test CUB',
      claimer: { email: 'test@cub.com' },
      status: 'pending',
      requestDate: '26 Dec 2022, 11:10',
      reference: 'z4MMk2',
      organisationId: '115599',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      requiredBankAccountDetails: {
        type: WireTransferType.DOMESTIC_WITH_BIC,
        fields: {
          accountNumber: {
            label: 'Account Number',
            regex: '/^[0-9A-Z]{1,17}$/',
            hint: 'This is your 1 to 17 numbers and letters bank account number',
            errorMessage: 'Please enter a valid code (1-17 characters, only characters and numbers).',
            required: true
          },
          bic: {
            label: 'BIC',
            regex: '/^[0-9A-Z]{1,17}$/',
            hint: 'This is your 1 to 17 numbers and letters bank account number',
            errorMessage: 'Please enter a valid code (1-17 characters, only characters and numbers).',
            required: true
          },
          bankCode: {
            label: 'Bank code',
            regex: '/^\\d{9}$/',
            hint: 'The ABA routing code is a nine-digit code that identifies the bank where your account is held',
            errorMessage: 'ABA routing code must be 9 digits long. Example: 123456789',
            required: false
          },
          branchCode: {
            label: 'Branch code',
            regex: '/^\\d{9}$/',
            hint: 'The ABA routing code is a nine-digit code that identifies the bank where your account is held',
            errorMessage: 'ABA routing code must be 9 digits long. Example: 123456789',
            required: false
          }
        }
      }
    }
  ]
}
