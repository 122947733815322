import dayjs from 'dayjs'
import { Alpha3Code } from 'i18n-iso-countries'

import {
  MutuallyExclusiveOrganisationTypes,
  OrganisationTypes
} from '@percent/admin-dashboard/constants/organisationTypes'
import { Validation } from '@percent/admin-dashboard/api/types'
import { mockOrganisation } from './mockOrganisationResponse'

export const mockValidation: Validation = {
  id: 'validationrequest_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
  name: 'Some charity',
  registryName: 'National Charities Registry',
  registryId: 'I343245678',
  email: 'test@percent.internal',
  addressLine1: '',
  addressLine2: '',
  city: '',
  postal: '',
  state: '',
  website: 'https://percent.internal',
  countryCode: 'GBR' as Alpha3Code,
  createdAt: '2020-10-13T17:46:54.000Z',
  deletedAt: null,
  acceptedAt: null,
  rejectedAt: null,
  validationSubmissionId: null,
  configuration: {
    name: 'Mock Configuration',
    allowedOrganisations: { types: [MutuallyExclusiveOrganisationTypes.Nonprofit] }
  },
  documents: [
    {
      id: 'validationrequestdocument_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      validationRequestId: 'validationrequest_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      url: 'https://s3-eu-west-1.amazonaws.com/assets.poweredbypercent.com/document.docx',
      createdAt: '2021-04-19T20:34:28Z',
      deletedAt: '2021-04-19T20:34:28Z'
    }
  ],
  partner: {
    id: 'test_partner_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
    name: 'test partner'
  },
  fieldConstraints: {
    fields: {
      registryId: {
        required: true,
        regex: '/^\\d{5,7}$/'
      }
    }
  }
}

export const approvedMockValidation = {
  ...mockValidation,
  organisationTypes: [MutuallyExclusiveOrganisationTypes.Nonprofit],
  acceptedAt: '2021-04-19T20:34:28Z',
  organisationId: 'staging_organisation_000000Cf0uCi7MiH6PbETrITPOYID'
}

export const rejectedMockValidation = {
  ...mockValidation,
  rejectedAt: '2021-04-19T20:34:28Z'
}

export const approvedInvalidOrganisationTypeMockValidation = {
  ...mockValidation,
  organisationTypes: [MutuallyExclusiveOrganisationTypes.Nonprofit],
  organisationId: 'staging_organisation_000000Cf0uCi7MiH6PbETrITPOYID',
  rejectedAt: '2021-04-19T20:34:28Z'
}

export const mockValidationOrganisation = {
  name: 'Example Registry',
  registry: 'staging_registry_000000C6l0xkGyM1bREbtiQmIV9HS',
  registryId: 'nm_automated_ric5d4c645-7e9f-4d7a-8ec6-97997ec92cd1'
}

export const mockValidationOrganisationWithRegistryData = {
  ...mockValidationOrganisation,
  registryData: { name: 'Example Registry' }
}

// TODO: create mock generator func
export const mockValidations = {
  data: [mockValidation],
  object: 'validation_request_admin',
  totalResults: 1,
  pageNumber: 1,
  pageSize: 25,
  _links: {
    current: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    next: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    prev: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D'
  }
}

export const createMockValidationResponse = (validation: Validation) => ({
  data: validation,
  object: 'validation_request_admin',
  totalResults: 1,
  pageNumber: 1,
  pageSize: 25,
  _links: {
    current: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    next: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    prev: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D'
  }
})

export const mockValidationResponse = {
  data: mockValidation,
  object: 'validation_request_admin',
  totalResults: 1,
  pageNumber: 1,
  pageSize: 25,
  _links: {
    current: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    next: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    prev: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D'
  }
}

export const mockValidations36HoursLeft = {
  ...mockValidations,
  data: [
    {
      ...mockValidations.data[0],
      createdAt: dayjs().subtract(38, 'h').toISOString()
    }
  ]
}

export const mockValidations14HoursLeft = {
  ...mockValidations,
  data: [
    {
      ...mockValidations.data[0],
      createdAt: dayjs().subtract(58, 'h').toISOString()
    }
  ]
}

export const mockValidations44HoursLeft = {
  ...mockValidations,
  data: [
    {
      ...mockValidations.data[0],
      createdAt: dayjs().subtract(28, 'h').toISOString()
    }
  ]
}

export const mockValidationsWithOrganisationRegistryData = {
  ...mockValidations,
  data: [
    {
      ...mockValidations.data[0],
      organisation: mockValidationOrganisationWithRegistryData
    }
  ]
}

export const mockValidationsWithoutOrganisationRegistryData = {
  ...mockValidations,
  data: [
    {
      ...mockValidations.data[0],
      organisation: mockValidationOrganisation
    }
  ]
}

export const mockValidationWithCorrectRegistryId = { ...mockValidation, registryId: '123456' }

export const mockRegisterlessValidation: Validation = {
  ...mockValidation,
  registryName: undefined,
  registryId: undefined
}

export const mockRegisterlessEducationValidation = {
  ...mockValidation,
  configuration: {
    name: 'Mock Configuration',
    allowedOrganisations: { types: [OrganisationTypes.Education] }
  },
  registryName: null,
  registryId: null
}
export const mockApprovedRegisterlessValidation: Validation = {
  ...approvedMockValidation,
  registryName: undefined,
  registryId: undefined,
  organisation: mockOrganisation
}

export const createMockValidationWithOrganisationTypes = (organisationTypes: OrganisationTypes[]): Validation => ({
  ...mockValidation,
  configuration: {
    ...mockValidation.configuration,
    allowedOrganisations: { types: organisationTypes }
  }
})
