import { useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as PdfIcon } from '../../assets/images/icons/pdf-icon.svg'
import { GalleryPreview } from './gallery-preview'
import {
  DocumentsGalleryProps,
  DocumentsGalleryThumbnailVariant,
} from './documents-gallery.types'

const Container = styled.div`
  display: flex;
  background: white;
  margin-left: auto;
  flex-direction: column;
  width: 100%;
`

const ThumbnailsWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.sizes.xs};
    margin-bottom: ${theme.sizes.m};
  `}
`

const Thumbnail = styled.button<{
  selected: boolean
  variant: DocumentsGalleryThumbnailVariant
}>`
  ${({ theme, selected, variant }) => `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.colors.primary400};
    border-radius: ${theme.sizes.xs};
    width: ${theme.sizes[16]};
    height: ${theme.sizes[16]};
    
    > img {
      width: ${theme.sizes[16]};
      height: ${theme.sizes[16]};
      object-fit: contain;
      overflow: hidden;
    }

    ${
      variant === 'green' &&
      `border-color: ${theme.colors.success400};
       &:before {
        content: '';
        position: absolute;
        width: ${theme.sizes[3]};
        height: ${theme.sizes[3]};
        background-color: ${theme.colors.success400};
        top: -5px;
        left: 6px;
        border-radius: 50%;
        transition: width .2s ease, height .2s ease;
        }
      `
    };
    ${selected && `border-width:2px;`};

    ${
      selected &&
      variant === 'green' &&
      `&:before {
        width: ${theme.sizes[4]};
        height: ${theme.sizes[4]};
        top: -7px;
      }
      `
    }
  `}
`

export const isPdfDocument = (url: string) => url.includes('.pdf')

export function DocumentsGallery({
  files,
  withPreview,
  previewUrl,
}: Readonly<DocumentsGalleryProps>) {
  const [selectedFileUrl, setSelectedFileUrl] = useState(
    withPreview ? files?.[0]?.url : ''
  )

  const onThumbnailClick = (src: string) => {
    if (withPreview) {
      setSelectedFileUrl(src)
      window.open(src, '_blank')
    } else {
      window.open(src, '_blank')
    }
  }

  if (!files?.length) return null
  return (
    <Container data-testid="documents-gallery">
      <ThumbnailsWrapper>
        {files?.map(({ url, variant = 'default' }, index) => (
          <Thumbnail
            key={`thumbnail-${url}`}
            selected={url === selectedFileUrl}
            variant={variant}
            onClick={() => onThumbnailClick(url)}
            data-testid={`${
              isPdfDocument(url) ? 'pdf' : 'img'
            }-thumbnail-${index}`}
          >
            {!isPdfDocument(url) ? (
              <img src={url} alt={`${index} thumbnail`} />
            ) : (
              <PdfIcon />
            )}
          </Thumbnail>
        ))}
      </ThumbnailsWrapper>

      {withPreview && (
        <GalleryPreview url={selectedFileUrl} previewUrl={previewUrl} />
      )}
    </Container>
  )
}
