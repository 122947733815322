import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, BadgeVariant, IconRegistry } from '@percent/lemonade'
import {
  DonationMatchRequestStatus,
  DonationMatchRequestUpdatedStatus
} from '@percent/admin-dashboard/api/actions/donationMatchRequests/donationMatchRequests.types'

export interface DonationMatchRequestStatusBadgeProps {
  status: DonationMatchRequestStatus
}

const variantMap: Record<DonationMatchRequestUpdatedStatus, BadgeVariant> = {
  pendingValidation: 'default',
  verificationSubmitted: 'default',
  approved: 'positive',
  rejected: 'critical'
}

const iconMap: Record<DonationMatchRequestUpdatedStatus, keyof typeof IconRegistry> = {
  pendingValidation: 'clock',
  verificationSubmitted: 'check',
  approved: 'check-badge',
  rejected: 'reject'
}

export function DonationMatchRequestStatusBadge({ status }: DonationMatchRequestStatusBadgeProps) {
  const { t } = useTranslation()

  const mappedStatus: DonationMatchRequestUpdatedStatus = useMemo(() => {
    if (status === 'processing') return 'pendingValidation'

    if (status === 'validating') return 'verificationSubmitted'

    return status
  }, [status])

  const translationMap: Record<DonationMatchRequestUpdatedStatus, string> = useMemo(
    () => ({
      pendingValidation: t('status.pendingValidation'),
      verificationSubmitted: t('status.verificationSubmitted'),
      approved: t('status.approved'),
      rejected: t('status.rejected')
    }),
    [t]
  )

  return (
    <Badge variant={variantMap[mappedStatus]} icon={iconMap[mappedStatus]}>
      {translationMap[mappedStatus]}
    </Badge>
  )
}
