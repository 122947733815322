import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CardDashboard, Tabs } from '@percent/lemonade'
import { ValidationSubmissionTopBarProps } from './ValidationSubmissionTopBar.types'
import styles from './ValidationSubmissionTopBar.module.scss'

export function ValidationSubmissionTopBar({
  id,
  organizationName,
  activeTab,
  setActiveTab
}: Readonly<ValidationSubmissionTopBarProps>) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const handleChange = (newTab: string) => {
    setActiveTab(newTab)
    history.push(newTab)
  }

  useEffect(() => {
    if (location) {
      setActiveTab(`${location.pathname}`)
    }
  }, [location, setActiveTab])

  return (
    <CardDashboard title={organizationName} className={styles.topBarContainer} data-testid="verifications-topbar">
      <Tabs
        activeTab={activeTab}
        onChangeTab={handleChange}
        items={[
          { key: `/validation-submissions/${id}`, label: t('typography.applicationDetails') },
          { key: `/validation-submissions/${id}/timeline`, label: t('typography.applicationTimeline') }
        ]}
      />
    </CardDashboard>
  )
}
